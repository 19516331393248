import './styles/App.css'
import CloseIcon from './assets/cancel.png'
import Navbar from './components/Navbar'
import UserAuthForm from './components/UserAuthForm'
import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from "react-router-dom"
import { useAuth } from "./context/AuthProvider"
import { getUserInfo } from "./common/track"

function Info(props) {
  const { setReady, setEmail, email } = props;
  const [ error, setError ] = useState(null);

  return <>
    <h1>Explore. Learn. Transform.</h1>
    <p>Stackcoco is an online adaptive exploration program designed for children aged 4 and up. </p> 
    <p>Covering thousands of topics across math, history, agriculture, science, technology and many more real-world knowledge, Stackcoco guides students in finding their life paths and achieving their fullest potential.</p>
    <div class="input-container">
      <input type="text" id="search-input" placeholder="Your Email" onChange={ e => { 
        if (error) { setError(null) } 
        setEmail(e.target.value) } 
      } />
      <button type="submit" id="submit-button" onClick={ () => { 
        // eslint-disable-next-line
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) { 
          setReady(true)
        }
        else {
          setError("Invalid Email Address.") 
        }
      }}>GET STARTED</button>
    </div>
    {
      error && <div id="EmailGetStartedError" class="TextInputError">{error}</div>
    }
  </>
}

function App() {
  const [ ready, setReady ] = useState(false)
  const [ email, setEmail ] = useState("")
  const { auth } = useAuth()
  const navigate = useNavigate()
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    getUserInfo({ refTag: searchParams?.get('r') })
    if (auth) { navigate("/p/stacks") }
    
    // eslint-disable-next-line
  }, [])

  return (
    <div className="App">
      <Navbar page="home" />
      <div className="Action">
        {
          !ready && <Info setReady={setReady} setEmail={setEmail} email={email} />
        }
        {
          ready && <div className="Close">
            <button onClick={ e => { setReady(false); setEmail(""); } }>
              <img src={CloseIcon} width="18" alt="close" />
            </button>
          </div>
        }
        {
          ready && <UserAuthForm defaultEmail={email} action="signup" />
        }
      </div>
      <ul className="Footer">
        <li><a href="/">STACKCOCO © 2023</a></li>
        <li><a href="#contact">Contact</a></li>
        <li><a href="#privacy">Privacy</a></li>
        <li><a href="#terms">Terms</a></li>
        {/* <li><a href="#plan">Master Plan</a></li> */}
      </ul>
    </div>
  );
}

export default App;
