import { createContext, useContext, useState, useEffect } from "react"
import { createClient } from '@supabase/supabase-js'
import Loading from '../components/Loading'

const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY)
const AuthContext = createContext({})
export const useAuth = () => useContext(AuthContext)

const login = (email, password) =>
  supabase.auth.signInWithPassword({ email, password })
  
const verifyHashToken = (token_hash, type) =>
  supabase.auth.verifyOtp({ token_hash, type })

const resendVerificationEmail = (email) =>
  supabase.auth.resend({
    type: 'signup',
    email
  })

const logout = () => {
  localStorage.removeItem('current_profile')
  supabase.auth.signOut()
}

const refreshSession = () => 
  supabase.auth.refreshSession()

const updatePassword = (password) =>
  supabase.auth.updateUser({ password })

const resetPasswordForEmail = (email) =>
  supabase.auth.resetPasswordForEmail(email, { redirectTo: `${process.env.REACT_APP_TLD}/auth/update-password` })

const findProfiles = () =>
  supabase.from('profiles').select('*')

// FIXME: testing only
const findTreasures = () =>
  supabase.from('treasures').select('*')

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [auth, setAuth] = useState(false)
  // Having this to be true by default is extremely important
  const [loading, setLoading] = useState(true)
  const isSubscribed = new Date() < new Date(user?.user_metadata?.subscribed)

  useEffect(() => {
    const getUser = async () => {
      // Get User
      const { data } = await supabase.auth.getUser()
      const { user: currentUser } = data

      setUser(currentUser ?? null)
      setAuth(currentUser ? true : false)
      setLoading(false)
    };
    getUser()

    const { data } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === "PASSWORD_RECOVERY") {
        setAuth(false)
      }
      else if (event === "SIGNED_IN") {
        setUser(session.user)
        setAuth(true)
      }
      else if (event === "SIGNED_OUT") {
        setUser(null)
        setAuth(false)
      }
    })

    return () => {
      data.subscription.unsubscribe()
    }
  }, [])

  return (
    <AuthContext.Provider value={{ 
      supabase,
      isSubscribed,
      setUser,
      user, 
      auth, 
      loading, 
      login, 
      verifyHashToken,
      resendVerificationEmail,
      logout, 
      refreshSession,
      updatePassword, 
      resetPasswordForEmail, 
      findProfiles,
      findTreasures, // FIXME: testing only
    }}>
      {/* TODO: finalize suspense loading page */}
      {loading && <Loading />}
      {!loading && children}
    </AuthContext.Provider>
  )
}

export default AuthProvider