import '../styles/Payment.css'

import { useState, useEffect  } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import { useStripePromise } from '../context/StripeProvider'
import { useAuth } from "../context/AuthProvider"

function SubscribeComplete(props) {
  const location = useLocation()
  const { setUser, refreshSession } = useAuth()
  const query = new URLSearchParams(location.search)
  const stripePromise = useStripePromise()
  const navigate = useNavigate()
  const [ status, setStatus ] = useState(null)

  // const payment_intent = query.get('payment_intent')
  const payment_intent_client_secret = query.get('payment_intent_client_secret')
  const action = query.get('action')

  async function getStripe() {
    const stripe = await stripePromise
    try {
      const r = await stripe?.retrievePaymentIntent(payment_intent_client_secret)

      switch (r?.paymentIntent.status) {
        case 'succeeded':
          setStatus("succeeded")
          break;

        case 'processing':
          setStatus("processing")
          break;

        case 'requires_payment_method':
          navigate("/p/stacks/subscribe", { replace: true })
          break;

        default:
          break;
      }
    }
    catch (e) {

    }
  }
  
  async function resetUserSession() {
    const r = await refreshSession()
    const sub = r.data.user?.user_metadata?.subscribed
    if (new Date() < new Date(sub)) {
      setUser(r.data.user)
    }
  }

  useEffect(() => {
    if (payment_intent_client_secret) {
      getStripe()
    }
    // resetUserSession()
  // eslint-disable-next-line
  }, [])

  return <div className="App">
    <div className="AppBg"></div>
    <div id="PaymentBody">
      <div className="PaymentContent">
        <div className="PaymentCompleted">
          <img src="https://stackco.co/avatars/star.jpg"
          alt="" />
          <div className="GetStarted">
            {
              (!action || action === "upgrading") && 
              <div className="PaymentNotice">Thanks for { action ? action : "subscribing" }!</div>
            }
            {
              action === "downgrading" &&
              <div className="PaymentNotice">Subscription successfully downgraded!</div>
            }
            {
              status === "processing" && 
              <div className="PaymentNotice">
                Payment is currently processing.<br/>
                Email hi@stackcoco.com if your membership is not activated!
              </div>
            }
            <button
              onClick={ () => { 
                localStorage.removeItem("stackcoco_last_refresh_mp")
                resetUserSession()
                navigate("/p/stacks", { replace: true })
              } }
            >
              Let's Get Started!
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default SubscribeComplete