import '../styles/App.css'
import '../styles/Auth.css'
import { useEffect, useState } from 'react'
import Navbar from './Navbar'
import { useNavigate, useParams } from "react-router-dom"
import { getUserInfo, track } from "../common/track"
import { useAuth } from "../context/AuthProvider"

function Confirmation() {
  const { token_hash } = useParams()
  const navigate = useNavigate()
  const { verifyHashToken, resendVerificationEmail } = useAuth()
  const [ loading, setLoading ] = useState(true)
  const [ email, setEmail ] = useState()
  const [ emailError, setEmailError ] = useState("")
  const [ notice, setNotice ] = useState(null)

  useEffect(() => {
    if (token_hash) {
      getUserInfo({ refTag: "confirmation" })

      // confirm token_hash with supabase
      verifyHashToken(token_hash, 'signup')
        .then(r => { 
          console.log("r", r) 
          if (r.data?.user) {
            navigate('/p/onboarding')
          }
          
          setLoading(false)
        })
        .catch(e => { })
    }
    else {
      navigate('/')
    }
  // eslint-disable-next-line
  }, [])

  return <div className="App">
    <Navbar page={`/auth/confirm`} />
    <div className="Action">
      {
        loading && <div>
          Loading ...
        </div>
      }
      {
        !loading && 
        !notice &&
        <div className="Auth">
          <div className="Notice"><b>Email link is invalid or expired!</b></div>
          <div className="Notice">Enter your email below to get another verification link.</div>
          <div>
            <input 
              type="text" 
              placeholder="Email" 
              value={email} 
              onChange={ e => {
                if (emailError) { setEmailError("") }
                setEmail(e.target.value) 
              }} 
            />
            
          </div>
          {
            emailError && <div class="TextInputError">{emailError}</div>
          }
          <div>
            <button onClick={ 
              async e => { 
                if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                  setEmailError("Invalid Email Address") 
                  return
                }

                try {
                  const r = await resendVerificationEmail(email)
                  if (r.error) {
                    track({ t: 'e', p: { event: "resend_verif_email", email, e: r.error?.message } })
                    setNotice(`Something went wrong. Please contact support at support@stackcoco.com. We apologize for the inconvenience!`)
                  }
                  else {
                    track({ p: { event: "resend_verif_email", email } })
                    setNotice(`Email is resent to ${email}`)
                  }
                }
                catch (e) {
                }
              } 
            }>
              Resend Email
            </button>
          </div>
        </div>
      }
      {
        notice &&
        <div>
          {notice}
        </div>
      }
    </div>
  </div>
}

export default Confirmation