import '../styles/Subscribe.css'
import { useState, useEffect } from 'react'
import { useOutletContext, Link, useLocation, useNavigate } from "react-router-dom"
import axios from 'axios'
import { useAuth } from "../context/AuthProvider"
// import { track } from "../common/track"
import wedgesIcon from "../assets/wedges.svg"
import cancelIcon from '../assets/cancel.png'

function Modal({ 
  showModal, 
  setShowModal,
}) {
  if (!showModal) return null

  return (
    <div className="ModalOverlay">
      <div className="ModalContent" style={{ maxHeight: 540, height: '72vh' }}>
        <div className="ModalHeader">
          <h2>Refund Policies</h2>
          <button
            className="CloseButton"
            onClick={() => setShowModal(false)}
          >
            <img src={cancelIcon} alt="close" />
          </button>
        </div>
        
        {/* Refund Policy */}
        <div 
          className="ModalBuy" 
          style={{ 
            flexDirection: 'column', 
            padding: '9px 0', 
            marginBottom: 18,
            gap: '18px', 
            maxHeight: '396px', 
            overflowY: 'auto',
        }}>
          <div className="Point">
            <h3>Last Updated: 8/16/2023</h3>
          </div>

          <div className="Point">
          Welcome to Stackcoco. This Refund Policy is meant to help you understand our practices related to the cancellation of your subscription and the corresponding refunds.
          </div>

          <div className="Point">
            <h3>1. Cancellation of Subscription</h3>
            At Stackcoco, we believe in flexibility. If for any reason you wish to cancel your subscription, you may do so at any time. Upon cancellation, your access to our service will terminate immediately.
          </div>
          
          <div className="Point">
            <h3>2. Refund Calculation</h3>
            Should you decide to cancel your subscription, we will refund the unused portion of your subscription fees based on the days you have not utilized.
            <br/><br/>
            The refund will be calculated as follows:
            <br/>
            a). The total subscription duration is considered as 365 days (regardless of leap year)
            <br/>
            b). We will determine the number of days from the start of your subscription to the day of cancellation.
            <br/>
            c). The refund will be the pro-rated amount for the days that were not used.
            <br/><br/>
            For example, if your annual subscription fee is $365 and you cancel after 30 days, you would have 335 unused days. You would then be refunded for these 335 days, which translates to $335.
          </div>
          
          <div className="Point">
            <h3>3. Refund Processing</h3>
            The refund will be credited back to the original method of payment. The processing time may vary depending on your payment provider's policies. Generally, you can expect to see the refund within 5-10 business days.
          </div>

          <div className="Point">
            <h3>4. Changes to This Policy</h3>
            Stackcoco reserves the right to modify this Refund Policy at any time. We will always post the most current version on our website. If we make changes that we believe substantially alter your rights, we will notify you.
          </div>

          <div className="Point">
            <h3>5. Contact Us</h3>
            If you have any questions or concerns about our Refund Policy, please reach out to our customer service team at hi@stackcoco.com
          </div>

        </div>

        <button className="CloseModal" onClick={ () => setShowModal(false) }>Back to Subscription</button>
      </div>
    </div>
  )
}

function Plan(props) {
  const { 
    plan, 
    planDetails, 
    index,
    action,
  } = props

  const { isSubscribed, user } = useAuth()
  const navigate = useNavigate()
  const isCurrentPlan = isSubscribed && user.user_metadata.product_id === plan.id
  const displayPrice = plan.price / 100.0

  return <div
    className="Plan"
  >
    <div className="PlanContent">
      {
        isCurrentPlan && <div style={{ color: 'red' }}>
          <b>Current Plan</b>
        </div>
      }
      <div className="PlanName">
        {plan.name}
      </div>

      {
        planDetails[index]?.subheader && <div className="PlanSubheader"><b>{planDetails[index].subheader}</b></div>
      }
      
      <div className="PlanPrice">${displayPrice} / year</div>

      {
        planDetails[index]?.sections.map((section, k) => {
          return <div
            className="PlanPoint"
            key={k}
          >
            <span class="Checkmark">✔</span> {section}
          </div>
        })
      }
      {
        !isCurrentPlan && <div>
          {
            !action && <Link
              to={`/p/stacks/payment`} 
              state={{ plan }} 
            >
              <div className="PlanButton">Subscribe</div>
            </Link>
          }
          {
            action && 
            <div>
              <button
                onClick={ async () => {
                  if (action === "downgrade") {
                    navigate('/p/subscribe/confirm', { state: { plan, type: "downgrade" } })
                  }
                  else if (action === "upgrade") {
                    navigate('/p/subscribe/confirm', { state: { plan, type: "upgrade" } })
                  }
                } }
                className="PlanButton"
              >
                {action}
              </button>
            </div>
          }
        </div>
      }
    </div>
  </div>
}

function Subscribe(props) {
  // eslint-disable-next-line
  const [ profiles, currentProfile ] = useOutletContext()
  const { supabase, isSubscribed, user } = useAuth()
  const location = useLocation()
  const [ plans, setPlans ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ openMorePlans, setOpenMorePlans ] = useState(isSubscribed)
  const [ showModal, setShowModal ] = useState(false)
  const action = isSubscribed ? location.pathname.includes("upgrade") ? "upgrade" : "downgrade" : undefined
  const maxProfiles = parseInt(user.user_metadata.max_profiles)
  const mainPlans = plans.filter(p => p.position <= 2).sort((a,b) => a.position - b.position)
  const morePlans = plans.filter(p => p.position > 2).sort((a,b) => a.position - b.position)

  const mainPlains_1 = (
      !isSubscribed || 
      (action === "upgrade" && maxProfiles === 1) || 
      action === "downgrade"
    ) ? mainPlans :
    action === "upgrade" && maxProfiles === 2 ? [mainPlans.pop()] :
    []
  const morePlains_1 = !isSubscribed ? morePlans :
    action === "upgrade" ? morePlans.filter(p => p.position >= maxProfiles) :
    action === "downgrade" ? morePlans.filter(p => p.position <= maxProfiles) :
    []

  // console.log("user", user)
  // console.log("mainPlains_1", mainPlains_1)
  // console.log("morePlains_1", morePlains_1)
  // console.log("[ profiles ]", profiles)

  const mainPlanDetails = [
    {
      subheader: "For ONE user",
      sections: [
        "Unlimited Access to All Content",
        "Personalized Growth Tracking"
      ],
    },
    {
      subheader: "For TWO users",
      sections: [
        "Unlimited Access to All Content",
        "Personalized Growth Tracking for Each User"
      ],
    }
  ]

  const MorePlanDetails = [
    {
      subheader: "For THREE users",
      sections: [
        "Unlimited Access to All Content",
        "Personalized Growth Tracking for Each User"
      ],
    },
    {
      subheader: "For FOUR users",
      sections: [
        "Unlimited Access to All Content",
        "Personalized Growth Tracking for Each User"
      ],
    },
    {
      subheader: "For FIVE users",
      sections: [
        "Unlimited Access to All Content",
        "Personalized Growth Tracking for Each User"
      ],
    },
    {
      subheader: "For SIX users",
      sections: [
        "Unlimited Access to All Content",
        "Personalized Growth Tracking for Each User"
      ],
    },
    {
      subheader: "For SEVEN users",
      sections: [
        "Unlimited Access to All Content",
        "Personalized Growth Tracking for Each User"
      ],
    },
    {
      subheader: "For EIGHT users",
      sections: [
        "Unlimited Access to All Content",
        "Personalized Growth Tracking for Each User"
      ],
    },
    {
      subheader: "For NINE users",
      sections: [
        "Unlimited Access to All Content",
        "Personalized Growth Tracking for Each User"
      ],
    },
  ]
 
  async function fetchPlans() {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/v1/stripe/products`
    const r = await axios.get(
      url,
      { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
    )
    setPlans(r.data.data)
    setLoading(false)
  }

  useEffect(() => {
    fetchPlans()
  // eslint-disable-next-line
  }, [])

  return <div className="App">
    <div className="AppBg"></div>
    <Modal 
      showModal={showModal} 
      setShowModal={setShowModal} 
    />
    <div id="SubscribeBody">
      <div className="SubscribeContent">
        {
          action && <div className="Title">
            {action} plan
          </div>
        }
        {
          loading && 
          <div className="Loading">
            <img src={wedgesIcon} alt="" width={200} height={200} />
            <span>Loading Subscription Plans ...</span>
          </div>
        }
        {
          !loading &&
          <div className="MainPlans">
            {
              mainPlains_1.map((plan, key) => <Plan 
                key={key}
                index={action === "upgrade" ? plan.position - 1 : key}
                plan={plan}
                planDetails={mainPlanDetails}
                action={action}
              />)
            }
          </div>
        }
        {
          !loading &&
          !isSubscribed &&
          <div className="Policies">
            <div className="Refund">
              Not fully satisfied? Cancel anytime. We will refund you for the days you have not used. <span style={{ cursor: 'pointer' }} onClick={ () => { setShowModal(true) }}><u>See details</u></span>
            </div>
            <div className="Manage">
              Want to add more or remove users later? Upgrade or downgrade anytime.
            </div>
          </div>
        }
        {
          !loading &&
          <div 
            className="MorePlans"
            style={{ 
              marginTop: isSubscribed ? 0 : "inherited"
            }}
          >
            {
              !openMorePlans && <div
                className="MorePlansButton"
              >
                <button
                  onClick={ () => {
                    setOpenMorePlans(true)
                  }}
                >
                  Need a plan with more users?
                </button>
              </div>
            }
            {
              openMorePlans && 
              morePlains_1.map((plan, key) => <Plan 
                key={key}
                index={key}
                plan={plan}
                planDetails={MorePlanDetails}
                action={action}
              />)
            }
          </div>
        }
      </div> 
    </div>
  </div>
}

export default Subscribe