import '../styles/Payment.css'

import { useState, useEffect  } from 'react'
import axios from 'axios'
import { useAuth } from "../context/AuthProvider"
import { formatDate } from "../common/utils"
import cancelIcon from '../assets/cancel.png'

function Modal({ 
  showModal, 
  setShowModal,
  manageSubscription,
}) {
  const { supabase } = useAuth()
  const [ password, setPassword ] = useState(null)
  const [ error, setError ] = useState(null)
  const [ processing, setProcessing ] = useState(null)

  async function cancelSubscription() {
    const r = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/v1/users/verify-password`,
      {
        password,
      },
      { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
    )

    if (r.error) {
      setError("Invalid Password")
    }
    else {
      await manageSubscription({ action: "cancel" })
    }
  }

  if (!showModal) return null

  return <div className="ModalOverlay">
    <div className="ModalContent" style={{ height: 162 }}>
      <div className="ModalHeader">
        <h3>Verify Password</h3>
        <button
          className="CloseButton"
          onClick={() => setShowModal(false)}
          style={{ marginTop: 6 }}
        >
          <img src={cancelIcon} alt="close" style={{ width: 16, height: 16 }} />
        </button>
      </div>
      <div>
        <input 
          type="password" 
          placeholder="Password" 
          value={password} onChange={ e => {
            setPassword(e.target.value) 
          }}
          style={{ 
            padding: '9px 18px',
            borderRadius: 9,
            border: '1px solid black',
            width: 'calc(100% - 36px)',
            fontSize: 15,
            marginBottom: -9,
          }}
        />
        {
          error && <div style={{ color: 'red', marginTop: 18, marginBottom: -9 }}>{error}</div>
        }
        <button
          onClick={ async () => {
            setProcessing(true)
            await cancelSubscription()
            setProcessing(false)
          }}
          className="ActionButton"
          disabled={!password || processing}
        >
          { processing ? `Processing ...` : "Confirm Cancel Subscription" }
        </button>
      </div>
    </div>
  </div>
}

function SubscribeCancel(props) {
  const [ refundAmount, setRefundAmount ] = useState(null)
  const [ daysLeftInCurrentPeriod, setDaysLeftInCurrentPeriod ] = useState(null)
  const [ originalSubscriptionAmount, setOriginalSubscriptionAmount ] = useState(null)
  const [ showModal, setShowModal ] = useState(false)
  const { supabase, user, logout } = useAuth()
  const expiryDate = new Date(user.user_metadata.subscribed)
  const endDate = formatDate(expiryDate)

  async function manageSubscription({ action, type }) {
    const r = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/v1/stripe/manage-subscriptions`,
      {
        action,
        type,
      },
      { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
    )

    const refundAmount_1 = r.data?.data?.refundAmount / 100
    const proratedDays = r.data?.data?.proratedDays
    const originalSubscriptionAmount_1 = r.data?.data?.originalSubscriptionAmount

    if (action === "upcoming") {
      setRefundAmount(refundAmount_1)
      setDaysLeftInCurrentPeriod(proratedDays)
      setOriginalSubscriptionAmount(originalSubscriptionAmount_1)
    }
    else {
      // Logout immediately after cancelling subscription.
      logout()
    }
  }

  useEffect(() => {
    manageSubscription({ action: "upcoming", type: "cancel" })

    return () => {
    }
  // eslint-disable-next-line
  }, [])

  return <div className="App">
    <div className="AppBg"></div>
    <Modal 
      showModal={showModal} 
      setShowModal={setShowModal} 
      manageSubscription={manageSubscription}
    />
    <div id="PaymentBody">
      <div className="PaymentContent">
        {
          refundAmount && <div className="CancelSubscription">
            <div class="Title">Cancel Subscription</div>
            
            <div>
              Days left for current subscription: {daysLeftInCurrentPeriod}
            </div>
            <div>
              End date for current subscription: {endDate}
            </div>
            <div>
              Current subscription price: ${originalSubscriptionAmount} / year
            </div>
            {/* <div> */}
              {/* TODO: add notice */}
              {/* All progress and tracking will stay in the account and can be downloaded any time even after cancellation. */}
            {/* </div> */}
            <div className="RefundAmount">
              Refund Amount: ${refundAmount}
            </div>
            <div>
              {/* TODO: form to ask cancel reason? */}

              <button
                onClick={ async () => { 
                  setShowModal(true)
                } }
                className="ActionButton"
              >
                Confirm Cancel Subscription
              </button>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
}

export default SubscribeCancel