import '../styles/Stacks.css'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { useOutletContext, useLocation, useNavigate, Outlet } from "react-router-dom"
import { useAuth } from "../context/AuthProvider"
import { track } from "../common/track"
import Feeds from "./Feeds"
import downArrow from "../assets/down-arrow.png"
import closeArrow from "../assets/close-arrow.png"

function sortCategories({ categories }) {
  const sorted = { interests: [], disciplines: [] }
  const interests = [], disciplines = []

  for (const category of categories) {
    if (category.type === "interests") {
      interests.push(category)
    }
    else if (category.type === "disciplines") {
      disciplines.push(category)
    }
  }

  sorted.interests = interests.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
  sorted.disciplines = disciplines.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

  let foundation = sorted.interests.find(item => item.name === 'Foundation');

  if (foundation) {
    // Filter out the 'Foundation' object
    sorted.interests = sorted.interests.filter(item => item.name !== 'Foundation');

    // Sort the remaining array
    sorted.interests.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    // Add 'Foundation' back to the front
    sorted.interests.unshift(foundation);
  }

  return sorted
}

function Stacks() {
  const { supabase, isSubscribed, refreshSession, setUser } = useAuth()
  // eslint-disable-next-line
  const [ profiles, currentProfile, setCurrentProfile, setRefreshProfiles ] = useOutletContext()
  const location = useLocation()
  const navigate = useNavigate()
  const [ feeds, setFeeds ] = useState([])
  const [ favorites, setFavorites ] = useState([])
  const [ taggings, setTaggings ] = useState([])
  const [ nextCursorFeeds, setNextCursorFeeds ] = useState(null)
  const [ nextCursorFavorites, setNextCursorFavorites ] = useState(null)
  const [ nextCursorTaggings, setNextCursorTaggings ] = useState(null)
  const [ nextCursorCategories, setCursorCategories ] = useState(null)
  const [ fetchMore, setFetchMore ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ openCategory, setOpenCategory ] = useState(false)
  const [ sortedCategories, setSortedCategories ] = useState({ interests: [], disciplines: [] })
  const [ steps, setSteps ] = useState([ { type: "feeds" } ])

  const lastStep = steps.slice(-1)[0]
  const atFavorites = lastStep?.type === "favorites"
  const atFeeds = lastStep?.type === "feeds"
  const atTaggings = lastStep?.type === "tags"

  const data = atTaggings ? taggings : atFavorites ? favorites : feeds
  const nextCursor = atTaggings ? nextCursorTaggings : atFavorites ? nextCursorFavorites : nextCursorFeeds
  const endOfStacks = data.length > 0 && !nextCursor
  const r2Dir = process.env.NODE_ENV === "development" ? "stage" : "prod"

  async function fetchFeeds(reset) {
    setFetchMore(false)
    setLoading(true)
    const limit = nextCursorFeeds ? process.env.REACT_APP_MAX_PROFILE : 18
    let url = `${process.env.REACT_APP_API_ENDPOINT}/v1/feeds?first=${limit}&profile_id=${currentProfile}`
    if (!reset && nextCursorFeeds) { url += `&after=${nextCursorFeeds}` }
    let data = [], next_cursor = null
    try {
      const r = await axios.get(url, { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } })
      data = r?.data?.data
      next_cursor = r?.data?.next_cursor
      track({ supabase, profile: currentProfile, p: { event: "find_feeds", data: data?.length, after: Boolean(nextCursorFeeds), reset, } })
    }
    catch (e) {
      track({ supabase, profile: currentProfile, t: 'e', p: { event: "find_feeds", after: Boolean(nextCursorFeeds), reset, e: e?.message } })
    }
    if (reset) { setFeeds(data) }
    else { setFeeds((prevFeeds) => [ ...prevFeeds, ...data ]) }
    setNextCursorFeeds(next_cursor)
    setLoading(false)
  }

  async function fetchTaggings(reset) {
    setFetchMore(false)
    setLoading(true)
    const limit = nextCursorTaggings ? process.env.REACT_APP_MAX_PROFILE : 18
    let url = `${process.env.REACT_APP_API_ENDPOINT}/v1/taggings?first=${limit}&tag_id=${lastStep.tag_id}`
    if (!reset && nextCursorTaggings) { url += `&after=${nextCursorTaggings}` }
    let data = [], next_cursor = null
    try {
      const r = await axios.get(url, { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } })
      data = r?.data?.data
      next_cursor = r?.data?.next_cursor
      track({ supabase, profile: currentProfile, p: { event: "find_taggings", tag_id: lastStep?.tag_id, data: data?.length, after: Boolean(nextCursorTaggings), reset, } })
    }
    catch (e) {
      track({ supabase, profile: currentProfile, t: 'e', p: { event: "find_taggings", tag_id: lastStep?.tag_id, after: Boolean(nextCursorTaggings), reset, e: e?.message } })
    }
    if (reset) { setTaggings(data) }
    else { setTaggings((prevTaggings) => [ ...prevTaggings, ...data ]) }
    setNextCursorTaggings(next_cursor)
    setLoading(false)
  }

  async function fetchCategories() {
    const limit = 198
    let url = `${process.env.REACT_APP_API_ENDPOINT}/v1/tags?first=${limit}`
    if (nextCursorCategories) { url += `&after=${nextCursorCategories}` }
    let data = [], next_cursor = null

    try {
      const r = await axios.get(url, { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } })
      data = r?.data?.data
      next_cursor = r?.data?.next_cursor
      track({ supabase, profile: currentProfile, p: { event: "find_categories", data: data?.length, after: Boolean(nextCursorCategories) } })
    }
    catch (e) {
      track({ supabase, profile: currentProfile, t: 'e', p: { event: "find_categories", after: Boolean(nextCursorCategories), e: e?.message } })
    }
    setCursorCategories(next_cursor)
    setSortedCategories(sortCategories({ categories: data }))
  }

  async function fetchFavorites(reset) {
    setFetchMore(false)
    setLoading(true)
    const limit = nextCursorFavorites ? process.env.REACT_APP_MAX_PROFILE : 18
    let url = `${process.env.REACT_APP_API_ENDPOINT}/v1/favorites?first=${limit}&profile_id=${currentProfile}`
    if (!reset && nextCursorFavorites) { url += `&after=${nextCursorFavorites}` }

    let data = [], next_cursor = null
    try {
      const r = await axios.get(url, { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } })
      data = r?.data?.data
      next_cursor = r?.data?.next_cursor
      track({ supabase, profile: currentProfile, p: { event: "find_favorites", data: data?.length, after: Boolean(nextCursorFavorites) } })
    }
    catch (e) {
      track({ supabase, profile: currentProfile, t: 'e', p: { event: "find_favorites", after: Boolean(nextCursorFavorites), e: e?.message } })
    }

    if (reset) { setFavorites(data) }
    else { setFavorites((prevFavorites) => [ ...prevFavorites, ...data ]) }
    setNextCursorFavorites(next_cursor)

    setLoading(false)
  }

  async function resetUserSession(mp) {
    const r = await refreshSession()

    if (parseInt(r.data.user?.user_metadata?.max_profiles) === parseInt(mp)) {
      localStorage.removeItem("stackcoco_last_refresh_mp")
    }

    const sub = r.data.user?.user_metadata?.subscribed
    if (new Date() < new Date(sub)) {
      setUser(r.data.user)
    }
  }

  // INSANELY IMPORTANT
  useEffect(() => {
    if (
      location?.state &&
      location?.state?.refreshProfiles
    ) {
      setRefreshProfiles(new Date().getTime())
    }
    else {
      if (profiles?.length === 0) {
        navigate("/p/onboarding")
      }
    }
  // eslint-disable-next-line
  }, [])

  // This is to ensure that all plan changes will get updated even if the user doesn't sign out
  useEffect(() => {
    const mp = localStorage.getItem("stackcoco_last_refresh_mp")
    if (mp) { resetUserSession(mp) }
  // eslint-disable-next-line
  }, [])
  
  useEffect(() => {
    const stackBody = document.getElementById('StacksBody')

    const handleScroll = async () => {
      const scrollTop = stackBody.scrollTop
      const scrollHeight = stackBody.scrollHeight
      const offsetHeight = stackBody.offsetHeight
      const contentHeight = scrollHeight - offsetHeight

      // Set FetchMore state to true when users scrolled almost to the bottom
      if (scrollTop > contentHeight * 0.8) {
        setFetchMore(true)
      }
    }

    stackBody.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (atFeeds) {
      fetchFeeds(true)
    }
    else if (atFavorites) {
      fetchFavorites(true)
    }
    else if (atTaggings) {
      fetchTaggings(true)
    }
  // eslint-disable-next-line
  }, [ atFavorites, atFeeds, atTaggings, steps, currentProfile ])

  useEffect(() => {
    if (fetchMore && !endOfStacks && !loading) {
      if (atFeeds) {
        fetchFeeds()
      }
      else if (atFavorites) {
        fetchFavorites()
      }
      else if (atTaggings) {
        fetchTaggings()
      }
    }
  // eslint-disable-next-line
  }, [ fetchMore, endOfStacks, loading ])

  useEffect(() => {
    if (openCategory) {
      if (sortedCategories.interests.length === 0) {
        fetchCategories()
      }
    }

  // eslint-disable-next-line
  }, [ openCategory ])

  return (<>
    {/* 
      This is to ensure Stack doesn't reset the infinite scroll 
      - /p/stacks
    */}
    {
      location.pathname.match(/p\/stacks\/\S+/) && 
      <div className="StackModal">
        <Outlet context={[ profiles, currentProfile, setCurrentProfile ]} />
      </div>
    }
    <div className="App">
      <div className="AppBg"></div>
      <div className="FilterBy" id="filters">
        <div className="FilterButtons">
          {/* <button onClick={ () => { navigate('/p/subscribe/complete') } }>test</button> */}
          <button 
            onClick={()=> { 
              track({ supabase, profile: currentProfile, p: { event: "open_categories", open_category: openCategory } })
              setOpenCategory(!openCategory) 
            }}
          >
            <span style={{ display: 'flex' }}>
              <span style={{ display: 'flex', marginLeft: "-9px" }}><img src={ openCategory ? closeArrow : downArrow} alt="" /></span>
              <span style={{ display: 'flex', alignItems: 'center' }}>Categories</span>
            </span>
          </button>
          <button 
            className={ atFavorites ? "SortByActiveBtn" : "" } 
            onClick={()=> { 
              setOpenCategory(false) 
              setSteps([{ type: "favorites" }]) 
            }}
          >
            My Favorites
          </button>
          <button 
            className={ atFeeds ? "SortByActiveBtn" : "" } 
            onClick={()=> { 
              setOpenCategory(false)
              setSteps([{ type: "feeds" }]) 
            }}
          >
            My Feed
          </button>
          {
            !["feeds", "favorites"].includes(lastStep.type) &&
            steps.length > 0 &&
            steps.filter(s => !["feeds", "favorites"].includes(s.type)).map((step, key) => {
              return <button
                key={key}
                className={ atTaggings && step.tag_id === lastStep.tag_id ? "SortByActiveBtn" : "" } 
                onClick={()=> { 
                  let updatedSteps = steps.slice()
                  const index = updatedSteps.map(s => s.tag_id).indexOf(step.tag_id)
                  updatedSteps.splice(index + 1)
                  setSteps(updatedSteps)
                }}
              >
                {step.name}
              </button>
            })
          }
        </div>
        
        {/* <div className="Search">
          <input 
            type="text" 
            placeholder="Search" 
            value={search} 
            onChange={ e => {
              setSearch(e.target.value) 
            }} 
          />
        </div> */}
        {
          !isSubscribed && <div className="SubscribeButton">
            <button
              onClick={ () => { navigate('/p/stacks/subscribe') } }
            >
              Subscribe
            </button>
          </div>
        }
      </div>
      <div id="MobileNotice">
        For optimal user experience, we recommend accessing Stackcoco via an iPad, laptop, or desktop computer 😄
      </div>
      <div className="StacksContent">
        {
          openCategory && <div className="StacksCategories">
            <div className="categoryHeader">Interests</div>
            {
              sortedCategories.interests.map((category, key) => <div
                key={key}
              >
                <span 
                  onClick={ () => { 
                    if (!lastStep.tag_id || lastStep.tag_id !== category.id) {
                      let updatedSteps = steps.slice()
                      updatedSteps = [{
                        type: "tags",
                        tag_id: category.id,
                        name: category.name
                      }]
                      setSteps(updatedSteps)
                    }
                  }}>
                    {category.name}
                  </span>
              </div>)
            }
            <div className="categoryHeader">Disciplines</div>
            {
              sortedCategories.disciplines.map((category, key) => <div
                key={key}
              >
                <span 
                  onClick={ () => { 
                    if (!lastStep.tag_id || lastStep.tag_id !== category.id) {
                      let updatedSteps = steps.slice()
                      updatedSteps[updatedSteps.length - 1] = {
                        type: "tags",
                        tag_id: category.id,
                        name: category.name
                      }
                      setSteps(updatedSteps)
                    }
                  }}>
                    {category.name}
                  </span>
              </div>)
            }
          </div>
        }
        <Feeds
          supabase={supabase}
          currentProfile={currentProfile}
          openCategory={openCategory}
          r2Dir={r2Dir}
          setSteps={setSteps}
          data={data}
          atFavorites={atFavorites}
          setFavorites={setFavorites}
        />
      </div>
    </div>
  </>);
}

export default Stacks;