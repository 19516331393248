import '../styles/Stacks.css'
import { useState } from 'react'
import axios from 'axios'
import { Link } from "react-router-dom"
import { track } from "../common/track"
import heartIcon from "../assets/heart.png"
import garbageIcon from "../assets/garbage.png"

function Block(props) {
  const { r2Dir, stack } = props

  return <>
    <div className="StackImage">
      <img src={`https://stackco.co/${r2Dir}/stacks/${stack.image_id}.jpg`} alt="" />
    </div>
    <div 
      className={ stack.name.length < 27 ? "StackName" : "StackName StackNameSmall" }
    >
      {stack.name}
    </div>
  </>
}

function Feeds(props) {
  const {
    supabase,
    currentProfile,
    openCategory,
    r2Dir,
    setSteps,
    data,
    atFavorites,
    setFavorites,
  } = props
  
  const [ favoriteNotice, setFavoriteNotice ] = useState({})

  return <div id="StacksBody">
    <div className="Stacks">
      {
        data.map((stack, key) => {
          return <div 
            key={key} 
            className="Stack"
            style={ openCategory ? {
              width: "calc(33% - 18px)",
              height: "calc(33% - 18px)"
            } : {} }
          >
            <div className="Favorite" onClick={ async () => {
              const url = `${process.env.REACT_APP_API_ENDPOINT}/v1/favorites`

              if (atFavorites) {
                try {
                  await axios.delete(
                    `${url}/${stack.favorite_id}?profile_id=${currentProfile}`,
                    { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
                  )
                  track({ supabase, profile: currentProfile, p: { event: "remove_favorite", favorite_id: stack.favorite_id, stack_id: stack.stack_id } })
                }
                catch (e) {
                  track({ supabase, profile: currentProfile, t: 'e', p: { event: "remove_favorite", favorite_id: stack.favorite_id, stack_id: stack.stack_id, e: e?.message } })
                }

                setFavorites(favorites => favorites.filter(f => f.favorite_id !== stack.favorite_id))
              }
              else {
                let data = null
                try {
                  const r = await axios.post(
                    url,
                    { 
                      profile_id: currentProfile,
                      stack_id: stack.stack_id,
                    }, 
                    { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
                  )
                  data = r?.data
                  track({ supabase, profile: currentProfile, p: { event: "add_favorite", stack_id: stack?.stack_id } })
                }
                catch (e) {
                  track({ supabase, profile: currentProfile, t: 'e', p: { event: "add_favorite", stack_id: stack?.stack_id, e: e?.message } })
                }

                setFavoriteNotice({
                  ...favoriteNotice,
                  [stack.stack_id]: data ? "Added to Favorites!" : "Already Added!"
                })

                setTimeout(function() {
                  setFavoriteNotice({
                    ...favoriteNotice,
                    [stack.stack_id]: null
                  })
                }, 1800);
              }
            } }>
              { atFavorites ? <img src={garbageIcon} alt="" /> : <img src={heartIcon} alt="" />}
            </div>
            {
              favoriteNotice[stack.stack_id] && <div className="FavoriteNotice">
                <span>
                  {favoriteNotice[stack.stack_id]}
                </span>
              </div>
            }
            {
              !stack.tag_id && <Link
                to={`/p/stacks/${stack.stack_id}`} 
                state={{ name: stack.name }} 
              >
                <Block r2Dir={r2Dir} stack={stack} />
              </Link>
            }
            {
              stack.tag_id && <div
                onClick={ () => { 
                  track({ supabase, profile: currentProfile, p: { event: "step_stack", tag_id: stack?.tag_id, name: stack?.name } })

                  setSteps(steps => {
                    return [
                      ...steps,
                      {
                        type: "tags",
                        tag_id: stack.tag_id,
                        name: stack.name,
                      }
                    ]
                  })
                } }
              >
                <Block r2Dir={r2Dir} stack={stack} />
              </div>
            }
          </div>
        })
      }
      {
        atFavorites && 
        data.length === 0 &&
        <div className="NoFavorites">
          No Favorites Added Yet 😄
        </div>
      }
    </div>
  </div>
}

export default Feeds;