import { useAuth } from "../context/AuthProvider"
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom"
import { useState, useEffect } from 'react'
import Navbar from './Navbar'
import Loading from './Loading'

const AuthRoute = () => {
  const { auth, findProfiles } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()

  const [ profiles, setProfiles ] = useState(null)
  const [ currentProfile, setCurrentProfile ] = useState(localStorage.getItem('current_profile'))
  const [ refreshProfiles, setRefreshProfiles ] = useState(null)

  useEffect(() => {
    async function fetchProfiles() {
      const r = await findProfiles()
      
      setProfiles(r.data)
      if (r.data.length === 1) {
        setCurrentProfile(r.data[0].id)
      }
    }
  
    if (!profiles || refreshProfiles) {
      fetchProfiles()
    }
  // eslint-disable-next-line
  }, [ refreshProfiles ])

  useEffect(() => {
    if (
      location.pathname !== '/p/profiles' && 
      profiles && 
      !profiles.find(p => p.id === currentProfile) &&
      profiles.length > 1
    ) {
      navigate('/p/profiles')
    }
  // eslint-disable-next-line
  }, [profiles])

  useEffect(() => {
    localStorage.setItem('current_profile', currentProfile)
  }, [currentProfile])

  return auth ? <>
      {
        !profiles && <Loading />
      }
      {
        profiles && <>
          <Navbar 
            profiles={profiles} 
            currentProfile={currentProfile} 
            setCurrentProfile={setCurrentProfile}
            setRefreshProfiles={setRefreshProfiles}
            page={location.pathname} 
          />
          <Outlet context={[ profiles, currentProfile, setCurrentProfile, setRefreshProfiles ]} />
        </>
      }
    </> : 
    <Navigate to={"/auth/login"} replace state={{ path: location.pathname }} />
  ;
};

export default AuthRoute;