import axios from 'axios'
import uuid from 'react-uuid'

export function track({
  supabase,
  profile = undefined,
  t = 'd',        // d | e
  p = undefined,
}) {
  let did = localStorage.getItem('stackcoco:device_id')
  let sid = sessionStorage.getItem('stackcoco:session_id')

  if (!did) {
    did = uuid()
    localStorage.setItem('stackcoco:device_id', uuid())
  }

  if (!sid) {
    sid = uuid()
    sessionStorage.setItem('stackcoco:session_id', uuid())
  }

  const trackData = {
    s: 'f',
    t,
    did,
    sid,
    pid: profile,
    p,
  }

  // console.log("Tracking:", trackData)

  const headers = {
    'Content-Type': 'application/json',
  }
  if (supabase)  {
    headers['Authorization'] = `Bearer ${supabase.realtime.accessToken}`
  }

  // no await here so it doesn't block
  axios.post(
    `${process.env.REACT_APP_TRACK_API_ENDPOINT}/track${ supabase ? '' : '-raw' }`, 
    {
      data: trackData,
      is_prod: process.env.NODE_ENV !== "development",
    },
    {
      headers,
    }
  )
  .catch((e) => { })
}

async function fetchIP() {
  const url = "https://ipapi.co/json"
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
    
    return await response.json()
  } catch (error) {
    return null
  }
}

export async function getUserInfo({ supabase, refTag }) {
  let ipData = undefined
  
  if (!localStorage.getItem('stackcoco:device_id')) {
    ipData = await fetchIP()
    console.log("ip", ipData)

    if (!ipData) { 
      track({ supabase, t: 'e', p: { e: 'ip_null' } })
    }
  }

  let p = {
    referrer: document.referrer,
    ref_tag: refTag,
  }
  if (ipData) { p.ip_data = ipData }

  track({ supabase, p })
}