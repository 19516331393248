import '../styles/Progress.css'
// import axios from 'axios'
import { useState, useEffect, useRef } from 'react'
import { useOutletContext } from "react-router-dom"
import { useAuth } from "../context/AuthProvider"
import loadingBarsIcon from '../assets/loadingBars.gif'
import weekData from '../assets/weeks.json'
import backIcon from '../assets/back.png'
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryLine } from 'victory'

const TEST_PROGRESS_STACK_DATA = [
  { d: "8-17-23", type: "day", stack_id: "", x: "Vehicles", y: 21 },
  { d: "8-17-23", type: "day", stack_id: "", x: "Math Additional L1", y: 20 },
  { d: "8-17-23", type: "day", stack_id: "", x: "Fruits", y: 19 },
  { d: "8-17-23", type: "day", stack_id: "", x: "Vegetables", y: 18 },
  { d: "8-17-23", type: "day", stack_id: "", x: "Amphibians", y: 15 },
  { d: "8-17-23", type: "day", stack_id: "", x: "Ocean Animals", y: 14 },
  { d: "8-17-23", type: "day", stack_id: "", x: "Flowers", y: 14 },
  { d: "8-17-23", type: "day", stack_id: "", x: "Dinosaurs", y: 13 },
  { d: "8-17-23", type: "day", stack_id: "", x: "Home Construction", y: 12 },
  { d: "8-18-23", type: "day", stack_id: "", x: "Fruits", y: 27 },
  { d: "8-18-23", type: "day", stack_id: "", x: "Vegetables", y: 21 },
  { d: "8-18-23", type: "day", stack_id: "", x: "Vehicles", y: 18 },
  { d: "8-18-23", type: "day", stack_id: "", x: "Math Additional L1", y: 16 },
  { d: "8-18-23", type: "day", stack_id: "", x: "Amphibians", y: 15 },
  { d: "8-18-23", type: "day", stack_id: "", x: "Home Construction", y: 14 },
  { d: "8-18-23", type: "day", stack_id: "", x: "Flowers", y: 14 },
  { d: "8-18-23", type: "day", stack_id: "", x: "Dinosaurs", y: 13 },
  { d: "8-18-23", type: "day", stack_id: "", x: "Ocean Animals", y: 12 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "01-01-23_01-07-23", y: 21 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "1-8-23_1-14-23", y: 11 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "1-15-23_1-21-23", y: 5 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "1-22-23_1-28-23", y: 23 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "1-29-23_2-4-23", y: 25 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "2-5-23_2-11-23", y: 61 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "2-12-23_2-18-23", y: 24 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "2-19-23_2-25-23", y: 77 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "2-26-23_3-4-23", y: 35 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "3-5-23_3-11-23", y: 52 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "3-12-23_3-18-23", y: 13 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "3-19-23_3-25-23", y: 4 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "3-26-23_4-1-23", y: 5 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "4-2-23_4-8-23", y: 9 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "4-9-23_4-15-23", y: 11 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "4-16-23_4-22-23", y: 21 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "4-23-23_4-29-23", y: 20 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "4-30-23_5-6-23", y: 19 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "5-7-23_5-13-23", y: 18 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "5-14-23_5-20-23", y: 15 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "5-21-23_5-27-23", y: 14 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "5-28-23_6-3-23", y: 17 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "6-4-23_6-10-23", y: 13 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "6-11-23_6-17-23", y: 12 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "6-18-23_6-24-23", y: 27 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "6-25-23_7-1-23", y: 21 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "7-2-23_7-8-23", y: 18 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "7-9-23_7-15-23", y: 16 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "7-16-23_7-22-23", y: 17 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "7-23-23_7-29-23", y: 14 },
  { type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "7-30-23_8-5-23", y: 28 },
  { d: "08-06-23_08-12-23", type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", x: "Ocean Animals", y: 27 },
  { d: "08-06-23_08-12-23", type: "week", stack_id: "", x: "Vehicles", y: 25 },
  { d: "08-06-23_08-12-23", type: "week", stack_id: "", x: "Amphibians", y: 19 },
  { d: "08-06-23_08-12-23", type: "week", stack_id: "", x: "Vegetables", y: 18 },
  { d: "08-06-23_08-12-23", type: "week", stack_id: "", x: "Home Construction", y: 17 },
  { d: "08-06-23_08-12-23", type: "week", stack_id: "", x: "Math Additional L1", y: 15 },
  { d: "08-06-23_08-12-23", type: "week", stack_id: "", x: "Flowers", y: 14 },
  { d: "08-06-23_08-12-23", type: "week", stack_id: "", x: "Dinosaurs", y: 13 },
  { d: "08-06-23_08-12-23", type: "week", stack_id: "", x: "Fruits", y: 8 },
  { d: "08-13-23_08-19-23", type: "week", stack_id: "", x: "Home Construction", y: 27 },
  { d: "08-13-23_08-19-23", type: "week", stack_id: "", x: "Vehicles", y: 21 },
  { d: "08-13-23_08-19-23", type: "week", stack_id: "", x: "Math Additional L1", y: 18 },
  { d: "08-13-23_08-19-23", type: "week", stack_id: "", x: "Vegetables", y: 16 },
  { d: "08-13-23_08-19-23", type: "week", stack_id: "", x: "Fruits", y: 17 },
  { d: "08-13-23_08-19-23", type: "week", stack_id: "", x: "Amphibians", y: 14 },
  { d: "08-13-23_08-19-23", type: "week", stack_id: "", x: "Flowers", y: 13 },
  { d: "08-13-23_08-19-23", type: "week", stack_id: "", x: "Dinosaurs", y: 12 },
  { d: "08-13-23_08-19-23", type: "week", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", x: "Ocean Animals", y: 11 },
  { type: "month", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "9-22", y: 17 },
  { type: "month", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "10-22", y: 13 },
  { type: "month", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "11-22", y: 12 },
  { type: "month", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "12-22", y: 27 },
  { type: "month", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "1-23", y: 21 },
  { type: "month", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "2-23", y: 18 },
  { type: "month", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "3-23", y: 16 },
  { type: "month", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "4-23", y: 17 },
  { type: "month", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "5-23", y: 14 },
  { type: "month", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "6-23", y: 28 },
  { type: "month", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae", d: "7-23", y: 13 },
  { d: "8-23", type: "month", stack_id: "", x: "Flowers", y: 130 },
  { d: "8-23", type: "month", stack_id: "", x: "Dinosaurs", y: 110 },
  { d: "8-23", type: "month", stack_id: "", x: "Amphibians", y: 100 },
  { d: "8-23", type: "month", stack_id: "", x: "Home Construction", y: 90 },
  { d: "8-23", type: "month", stack_id: "", x: "Fruits", y: 70 },
  { d: "8-23", type: "month", stack_id: "", x: "Ocean Animals", y: 60 },
  { d: "8-23", type: "month", stack_id: "", x: "Vehicles", y: 55 },
  { d: "8-23", type: "month", stack_id: "", x: "Math Additional L1", y: 34 },
  { d: "8-23", type: "month", stack_id: "", x: "Vegetables", y: 11 },
]

// const TEST_PROGRESS_TERM_DATA = [
//   { d: "8-13-23_8-19-23", type: "week", term_id: "", stack_id: "", x: "Effiel Tower", y: 27, subtitle: "Modern Architecture" },
//   { d: "8-13-23_8-19-23", type: "week", term_id: "", stack_id: "", x: "Protons", y: 21, subtitle: "Physics Level 1" },
//   { d: "8-13-23_8-19-23", type: "week", term_id: "", stack_id: "", x: "Synchronicities", y: 18, subtitle: "Carl Jung" },
//   { d: "8-13-23_8-19-23", type: "week", term_id: "", stack_id: "", x: "1 + 1", y: 17, subtitle: "Addition Level 1" },
//   { d: "8-13-23_8-19-23", type: "week", term_id: "", stack_id: "", x: "Edible Frog", y: 16, subtitle: "Amphibians" },
//   { d: "8-13-23_8-19-23", type: "week", term_id: "", stack_id: "", x: "A1: Lessons", y: 14, subtitle: "Early Reader 1" },
//   { d: "8-13-23_8-19-23", type: "week", term_id: "", stack_id: "", x: "2 + 2", y: 13, subtitle: "Addition Level 1" },
//   { d: "8-13-23_8-19-23", type: "week", term_id: "", stack_id: "", x: "2 + 4", y: 12, subtitle: "Addition Level 1" },
//   { d: "8-13-23_8-19-23", type: "week", term_id: "", stack_id: "", x: "The fox and the grapes", y: 11, subtitle: "Aesop's Fables" },
//   { d: "8-23", type: "month", term_id: "", stack_id: "", x: "Protons", y: 23, subtitle: "Physics Level 1" },
//   { d: "8-23", type: "month", term_id: "", stack_id: "", x: "Effiel Tower", y: 21, subtitle: "Modern Architecture" },
//   { d: "8-23", type: "month", term_id: "", stack_id: "", x: "Synchronicities", y: 18, subtitle: "Carl Jung" },
//   { d: "8-23", type: "month", term_id: "", stack_id: "", x: "1 + 1", y: 17, subtitle: "Addition Level 1" },
//   { d: "8-23", type: "month", term_id: "", stack_id: "", x: "Edible Frog", y: 16, subtitle: "Amphibians" },
//   { d: "8-23", type: "month", term_id: "", stack_id: "", x: "A1: Lessons", y: 14, subtitle: "Early Reader 1" },
//   { d: "8-23", type: "month", term_id: "", stack_id: "", x: "2 + 2", y: 13, subtitle: "Addition Level 1" },
//   { d: "8-23", type: "month", term_id: "", stack_id: "", x: "2 + 4", y: 12, subtitle: "Addition Level 1" },
//   { d: "8-23", type: "month", term_id: "", stack_id: "", x: "The fox and the grapes", y: 11, subtitle: "Aesop's Fables" },
// ]

function getFullDate(dateKey, isFullYear=false) {
  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ]
  const [month, day, yearShort] = dateKey.split("-").map(Number)
  return `${months[month - 1]} ${day}, ${isFullYear ? `${2000+parseInt(yearShort)}` : yearShort}`
}

function getFullMonth(monthKey) {
  const month = monthKey.split('-')[0]
  const year = parseInt(monthKey.split('-')[1])

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ]
  
  return `${months[month-1]}, ${2000 + year}`
}

function getFullWeek(weekKey) {
  const startWeek = weekKey.split("_")[0]
  const endWeek = weekKey.split("_")[1]

  const startWeek_1 = getFullDate(startWeek)
  const endWeek_1 = getFullDate(endWeek)

  return `${startWeek_1} to ${endWeek_1}`
}

function filterWeeksUpToLatest(weekArray, lowerBound) {
  if (!lowerBound) return weekArray;

  const currentDate = new Date()

  // console.log(`Current date: ${currentDate}`);
  // console.log(`Lower bound: ${lowerBound}`);

  return weekArray.filter(weekRange => {
    const [startRange, endRange] = weekRange.split('_');
    
    if (!startRange || !endRange) {
      // console.log(`Invalid weekRange format for: ${weekRange}`);
      return false;
    }

    let [startMonth, startDay, startYear] = startRange.split('-').map(Number);
    let [endMonth, endDay, endYear] = endRange.split('-').map(Number);

    // Convert two-digit years to four digits
    startYear += (startYear < 100 ? 2000 : 0);
    endYear += (endYear < 100 ? 2000 : 0);

    const startDate = new Date(startYear, startMonth - 1, startDay);
    
    // Adjusting the end date to the end of the day
    const endDate = new Date(endYear, endMonth - 1, endDay, 23, 59, 59, 999);

    // console.log(`Week range: ${weekRange}`);
    // console.log(`Start date: ${startDate}`);
    // console.log(`End date: ${endDate}`);

    if (startDate <= currentDate && endDate >= lowerBound) {
      // console.log(`${weekRange} is within the specified range.`);
      return true;
    }

    return false;
  });
}

async function testClosestTimes() {
  await new Promise(resolve => setTimeout(resolve, 998))

  return {
    day: "8-18-23",
    week: "8-13-23_8-19-23",
    month: "8-23",
  }
}

// TODO: replace this with axios
// PER YEAR MAX: day - 365 x 9 ; week - 52 x 9 ; month - 12 x 9 
async function testDayData(date) {
  await new Promise(resolve => setTimeout(resolve, 998))

  const rawDayData = TEST_PROGRESS_STACK_DATA.filter(d => d.type === 'day')

  let defaultDate = rawDayData[rawDayData.length-1].d
  if (date) {
    return rawDayData.filter(data => data.d === date)
  }
  else {
    return rawDayData.filter(data => data.d === defaultDate)
  }
}

// TODO: replace this with axios
async function testWeekData(week) {
  await new Promise(resolve => setTimeout(resolve, 998))

  const rawWeekData = TEST_PROGRESS_STACK_DATA.filter(d => d.type === 'week')

  let defaultMonth = rawWeekData[rawWeekData.length-1].d
  if (week) {
    return rawWeekData.filter(data => data.d === week)
  }
  else {
    return rawWeekData.filter(data => data.d === defaultMonth)
  }
}

// TODO: replace this with axios
async function testMonthData(month) {
  await new Promise(resolve => setTimeout(resolve, 998))
  
  const rawMonthData = TEST_PROGRESS_STACK_DATA.filter(d => d.type === 'month')

  let defaultMonth = rawMonthData[rawMonthData.length-1].d
  if (month) {
    return rawMonthData.filter(data => data.d === month)
  }
  else {
    return rawMonthData.filter(data => data.d === defaultMonth)
  }
}

// TODO: replace this with axios
// PER YEAR MAX: week - 52 x 99
async function testTrendData(stack_id, duration="week") {
  await new Promise(resolve => setTimeout(resolve, 998))

  let output = []
  //
  const rawDayData = duration === "week" ? TEST_PROGRESS_STACK_DATA.filter(d => d.type === 'week' && d.stack_id === "0188456e-4760-71b2-8749-c4cdc76a52ae") :
    TEST_PROGRESS_STACK_DATA.filter(d => d.type === 'month' && d.stack_id === "0188456e-4760-71b2-8749-c4cdc76a52ae")

  let defaultStackId = rawDayData[rawDayData.length-1].stack_id
  if (stack_id) {
    output = rawDayData.filter(d => d.stack_id === stack_id)
  }
  else {
    output = rawDayData.filter(d => d.stack_id === defaultStackId)
  } 

  return output.map(o => ({
    ...o,
    x: duration === "week" ? getFullWeek(o.d) : getFullMonth(o.d)
  }))
}

async function testPopularStacks() {
  await new Promise(resolve => setTimeout(resolve, 998))

  let popularStacks = [
    { name: "Ocean Animals", stack_id: "0188456e-4760-71b2-8749-c4cdc76a52ae" },
    { name: "Dinosaurs", stack_id: "0188bc10-cdef-7000-8116-c2900d4a0c3d" },
    { name: "Art Supplies", stack_id: "0188d1ca-2a4f-7000-a37f-ad5559ffb9b4" },
    { name: "Beverages", stack_id: "018986a0-567b-7000-b525-f823fe7cadac" },
    { name: "Amphibians", stack_id: "01884569-41e7-76ab-bedf-667994f4ab78" },
    { name: "Pasta", stack_id: "0188456d-2c02-7b83-a3bf-2c5f7dc225b0" },
    { name: "Mushrooms", stack_id: "0188456e-5d91-77f1-beba-53ae1a9bc450" },
    { name: "Breads", stack_id: "0188c635-d85b-7000-bc88-744368ad2362" },
    { name: "Countries", stack_id: "0188e155-239f-7000-970b-9906fb573b9f" },
  ]

  return popularStacks
}

async function testUnexploredStacks() {
  await new Promise(resolve => setTimeout(resolve, 998))

  let unexploredStacks = [
    { name: "Extreme Weather", stack_id: "01892e7f-7729-7000-9060-c2230f197a75" },
    { name: "Modern Architecture", stack_id: "01891c68-e370-7000-a998-8fab99cfcaad" },
    { name: "Ancient Wonders", stack_id: "01891c76-0616-7000-9f60-c37acf1b0d8b" },
    { name: "Gemstones", stack_id: "0188ea3c-4ee0-7000-b5dc-3844a83b8da3" },
    { name: "Vehicles", stack_id: "0188e8f3-8960-7000-91a7-4b447fc8acb1" },
  ]

  return unexploredStacks
}

async function testWeekTerms(week) {
  await new Promise(resolve => setTimeout(resolve, 998))
  
  let rawWeekData = [
    // { d: "8-6-23_8-12-23", x: "", y: 27 },
    // { d: "8-6-23_8-12-23", x: "", y: 25 },
    // { d: "8-6-23_8-12-23", x: "", y: 19 },
    // { d: "8-6-23_8-12-23", x: "", y: 18 },
    // { d: "8-6-23_8-12-23", x: "", y: 17 },
    // { d: "8-6-23_8-12-23", x: "", y: 15 },
    // { d: "8-6-23_8-12-23", x: "", y: 14 },
    // { d: "8-6-23_8-12-23", x: "", y: 13 },
    // { d: "8-6-23_8-12-23", x: "", y: 8 },
    { d: "8-13-23_8-19-23", x: "Effiel Tower", y: 27, subtitle: "Modern Architecture" },
    { d: "8-13-23_8-19-23", x: "Protons", y: 21, subtitle: "Physics Level 1" },
    { d: "8-13-23_8-19-23", x: "Synchronicities", y: 18, subtitle: "Carl Jung" },
    { d: "8-13-23_8-19-23", x: "1 + 1", y: 17, subtitle: "Addition Level 1" },
    { d: "8-13-23_8-19-23", x: "Edible Frog", y: 16, subtitle: "Amphibians" },
    { d: "8-13-23_8-19-23", x: "A1: Lessons", y: 14, subtitle: "Early Reader 1" },
    { d: "8-13-23_8-19-23", x: "2 + 2", y: 13, subtitle: "Addition Level 1"  },
    { d: "8-13-23_8-19-23", x: "2 + 4", y: 12, subtitle: "Addition Level 1"  },
    { d: "8-13-23_8-19-23", x: "The fox and the grapes", y: 11, subtitle: "Aesop's Fables"  },
  ]

  let defaultWeek = rawWeekData[rawWeekData.length-1].d
  if (week) {
    return rawWeekData.filter(data => data.d === week)
  }
  else {
    return rawWeekData.filter(data => data.d === defaultWeek)
  }
}

async function testMonthTerms(month) {
  await new Promise(resolve => setTimeout(resolve, 998))

  let rawMonthData = [
    { d: "8-23", x: "Protons", y: 23, subtitle: "Physics Level 1" },
    { d: "8-23", x: "Effiel Tower", y: 21, subtitle: "Modern Architecture" },
    { d: "8-23", x: "Synchronicities", y: 18, subtitle: "Carl Jung" },
    { d: "8-23", x: "1 + 1", y: 17, subtitle: "Addition Level 1" },
    { d: "8-23", x: "Edible Frog", y: 16, subtitle: "Amphibians" },
    { d: "8-23", x: "A1: Lessons", y: 14, subtitle: "Early Reader 1" },
    { d: "8-23", x: "2 + 2", y: 13, subtitle: "Addition Level 1"  },
    { d: "8-23", x: "2 + 4", y: 12, subtitle: "Addition Level 1"  },
    { d: "8-23", x: "The fox and the grapes", y: 11, subtitle: "Aesop's Fables"  },
  ]

  let defaultMonth = rawMonthData[rawMonthData.length-1].d
  if (month) {
    return rawMonthData.filter(data => data.d === month)
  }
  else {
    return rawMonthData.filter(data => data.d === defaultMonth)
  }
}

function listYearsBetweenDates(startDate, endDate) {
  const startYear = startDate.getFullYear()
  const endYear = endDate.getFullYear()
  const years = []

  for (let year = startYear; year <= endYear; year++) {
    years.push(year)
  }

  return years
}

function getDropdownInfo(user) {
  const userCreatedAt = new Date(user.created_at)
  const now = new Date()

  const years = listYearsBetweenDates(userCreatedAt, now)
  let weeks = []

  for (const year of years) {
    let weekData_1 = [ ...weekData[year] ]
    
    if (
      (year === years[years.length-1] && years.length === 1) ||
      (year === years[0] && years.length > 1)
    ) {
      weekData_1 = filterWeeksUpToLatest(weekData_1, userCreatedAt)
    }

    weekData_1.reverse()

    for (const week of weekData_1) {
      weeks.push({ id: week, name: getFullWeek(week) })
    }
  }
  
  return {
    weeks,
    years,
  }
}

function DateDropdownForm(props) {
  const { setIsMenuOpen, currentSelector, setCurrentSelector, type } = props
  const { user } = useAuth()
  const { years, weeks } = getDropdownInfo(user)

  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(today.getDate() - 1) // Set it to yesterday

  const [month, setMonth] = useState(String(yesterday.getMonth() + 1)) // Month is 0-indexed
  const [day, setDay] = useState(String(yesterday.getDate()))
  const [year, setYear] = useState(String(yesterday.getFullYear() - 2000)) // Representing it in 'XX' format
  const [week, setWeek] = useState()

  const dateKey = `${month}-${day}-${year}`
  const monthKey = `${month}-${year}`

  const getDays = (m, y) => {
    return new Date(y, m, 0).getDate()
  }

  useEffect(() => {
    if (currentSelector?.day?.id) {
      const defaultDateKey = currentSelector.day.id ? currentSelector.day.id.split('-') : null
      setMonth(defaultDateKey[0])
      setDay(defaultDateKey[1])
      setYear(defaultDateKey[2])
    }
  // eslint-disable-next-line
  }, [ currentSelector?.day ])

  useEffect(() => {
    if (currentSelector?.week?.id) {
      setWeek(currentSelector?.week?.id)
    }
  // eslint-disable-next-line
  }, [ currentSelector?.week ])

  useEffect(() => {
    if (currentSelector?.month?.id) {
      const defaultMonthKey = currentSelector.month.id ? currentSelector.month.id.split('-') : null
      setMonth(defaultMonthKey[0])
      setYear(defaultMonthKey[1])
    }
  // eslint-disable-next-line
  }, [ currentSelector?.month ])

  return (<>
    <div className="DropDownContent">
      {
        type === "week" &&
        <select
          style={{ width: "100%" }}
          value={week} 
          onChange={(e) => setWeek(e.target.value)}
        >
          {
            weeks.map((w, key) => 
              <option key={key} value={w.id}>{w.name}</option>
            )
          }
        </select>
      }
      {
        [ "day", "month" ].includes(type) &&
        <>
          <select 
            style={{ width: type === "day" ? "calc(100% / 3)" : "50%" }}
            value={month} 
            onChange={(e) => setMonth(e.target.value)}
          >
            {Array.from({ length: 12 }, (_, i) => i + 1).map(m => 
              <option key={m} value={m}>{m}</option>
            )}
          </select>
          {
            type === "day" && <select 
              style={{ width: "calc(100% / 3)" }}
              value={day} 
              onChange={(e) => setDay(e.target.value)}
            >
              {Array.from({ length: getDays(month, year) }, (_, i) => i + 1).map(d => 
                <option key={d} value={d}>{d}</option>
              )}
            </select>
          }
          <select
            style={{ width: type === "day" ? "calc(100% / 3)" : "50%" }}
            value={year} 
            onChange={(e) => setYear(e.target.value)}
          >
            {years.map(y => <option key={y} value={y - 2000}>{y - 2000}</option>)}
          </select>
        </>
      }
    </div>
    <div className="DropDownButtons">
      <div className="DropDownCancel">
        <button onClick={ () => { setIsMenuOpen(false) }}>Cancel</button>
      </div>
      <div className="DropDownSubmit">
        <button 
          onClick={ () => { 
            const updateRow = type === "day" ? { day: { id: dateKey, name: getFullDate(dateKey, true) } } :
              type === "week" ? { week: { id: week, name: getFullWeek(week) } } :
              type === "month" ? { month: { id: monthKey, name: getFullMonth(monthKey) } } : {}

            setCurrentSelector({
              ...currentSelector, 
              ...updateRow,
            })
            setIsMenuOpen(false) 
          }}
        >
          Confirm
        </button>
      </div>
    </div>
  </>)
}

function Settings(props) {
  const { 
    currentTab, 
    setCurrentFilter, 
    currentFilter, 
    setCurrentSelector, 
    currentSelector,
    setStacksTrendType,
    stacksTrendType, 
    setCurrentStack,
    currentStack,
  } = props

  const [ isMenuOpen, setIsMenuOpen ] = useState(false)
  const menuRef = useRef(null)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }
  
  useEffect(() => {
    if (isMenuOpen) {
      setIsMenuOpen(false)
    }
  // eslint-disable-next-line
  }, [ currentFilter ])

  return <div className="ProgressSettings">
    <div className="ProgressFilters">
      {
        ["top_stacks", "top_terms"].includes(currentTab) &&
        <>
          <div style={{ marginRight: 3 }}>Filter by</div>
          {
            currentTab === "top_stacks" &&
            <div 
              onClick={ () => { setCurrentFilter("day") } }
              className={`${ currentFilter === "day" ? "ProgressFilter CurrentProgressFilter" : "ProgressFilter" }`}
            >
              Day
            </div>}
          
          <div 
            onClick={ () => { setCurrentFilter("week") } }
            className={`${ currentFilter === "week" ? "ProgressFilter CurrentProgressFilter" : "ProgressFilter" }`}
          >
            Week
          </div>
          <div 
            onClick={ () => { setCurrentFilter("month") } }
            className={`${ currentFilter === "month" ? "ProgressFilter CurrentProgressFilter" : "ProgressFilter" }`}
          >
            Month
          </div>
        </>
      }
      {
        currentTab === "stacks_trend" && 
        !currentStack &&
        <>
          <div
            onClick={ () => { setStacksTrendType("interested") } }
            className={`${ stacksTrendType === "interested" ? "ProgressFilter CurrentProgressFilter" : "ProgressFilter" }`}
          >
            Interested
          </div>
          <div
            onClick={ () => { setStacksTrendType("unexplored") } }
            className={`${ stacksTrendType === "unexplored" ? "ProgressFilter CurrentProgressFilter" : "ProgressFilter" }`}
          >
            Unexplored
          </div>
        </>
      }
      {
        currentTab === "stacks_trend" &&
        currentStack &&
        <>
          <div 
            style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
            onClick={ () => { 
              setCurrentStack(null)
            } }
          >
            <div 
              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginRight: 9 }}
            >
              <img src={backIcon} width={15} alt="" />
            </div>
            <div>
              Stacks
            </div>
          </div>
        </>
      }
    </div>

    {
      ["top_stacks", "top_terms"].includes(currentTab) &&
      <div className="ProgressSelector">
        {
          currentFilter === "day" && <>
            <label>Day</label>
            <div className="ProgressSelect" ref={menuRef} onClick={toggleMenu}>
              {currentSelector?.[currentFilter]?.name}
            </div>
            <div
              style={{ position: 'absolute', zIndex: 2 }}
            >
              {isMenuOpen && (
                <div className="ProgressSelectDropDown">
                  <DateDropdownForm 
                    setIsMenuOpen={setIsMenuOpen}
                    currentSelector={currentSelector}
                    setCurrentSelector={setCurrentSelector}
                    type="day"
                  />
                </div>
              )}
            </div>
          </>
        }
        {
          currentFilter === "week" && <>
            <label>Week</label>
            <div className="ProgressSelect" ref={menuRef} onClick={toggleMenu}>
              {currentSelector?.week?.name}
            </div>
            <div
              style={{ position: 'absolute', zIndex: 2 }}
            >
              {isMenuOpen && (
                <div className="ProgressSelectDropDown">
                  <DateDropdownForm 
                    setIsMenuOpen={setIsMenuOpen}
                    currentSelector={currentSelector}
                    setCurrentSelector={setCurrentSelector}
                    type="week"
                  />
                </div>
              )}
            </div>
          </>
        }
        {
          currentFilter === "month" && <>
            <label>Month</label>
            <div className="ProgressSelect" ref={menuRef} onClick={toggleMenu}>
              {currentSelector?.month?.name}
            </div>
            <div
              style={{ position: 'absolute', zIndex: 2 }}
            >
              {isMenuOpen && (
                <div className="ProgressSelectDropDown">
                  <DateDropdownForm 
                    setIsMenuOpen={setIsMenuOpen}
                    currentSelector={currentSelector}
                    setCurrentSelector={setCurrentSelector}
                    type="month"
                  />
                </div>
              )}
            </div>
          </>
        }
      </div>
    }
    {
      currentTab === "stacks_trend" &&
      currentStack && 
      <div className="ProgressSelector" style={{ gap: '9px' }}>
        <div 
          onClick={ () => { setCurrentFilter("week") } }
          className={`${ currentFilter === "week" ? "ProgressFilter CurrentProgressFilter" : "ProgressFilter" }`}
        >
          Week
        </div>
        <div 
          onClick={ () => { setCurrentFilter("month") } }
          className={`${ currentFilter === "month" ? "ProgressFilter CurrentProgressFilter" : "ProgressFilter" }`}
        >
          Month
        </div>
      </div>
    }
  </div>
}

function TopStacks(props) {
  const { currentTab, closestTimes, chosenProfile } = props
  const [ loading, setLoading ] = useState(true)
  const [ topStacksData, setTopStacksData ] = useState([])
  const [ currentSelector, setCurrentSelector ] = useState()
  const [ currentFilter, setCurrentFilter ] = useState("day")

  async function fetchDayData(date) {
    if (!loading) {
      setLoading(true)
    }
    const data = await testDayData(date)
    setTopStacksData(data)
    setLoading(false)
  }

  async function fetchWeekData(week) {
    setLoading(true)
    const data = await testWeekData(week)
    setTopStacksData(data)
    setLoading(false)
  }

  async function fetchMonthData(month) {
    setLoading(true)
    const data = await testMonthData(month)
    setTopStacksData(data)
    setLoading(false)
  }

  useEffect(() => {
    if (
      closestTimes?.day &&
      closestTimes?.week &&
      closestTimes?.month
    ) {
      setCurrentSelector({ 
        day: {
          id: closestTimes?.day, 
          name: getFullDate(closestTimes?.day, true)
        },
        week: {
          id: closestTimes?.week,
          name: getFullWeek(closestTimes?.week),
        },
        month: {
          id: closestTimes?.month,
          name: getFullMonth(closestTimes?.month),
        }
      })
    }
  // eslint-disable-next-line
  }, [ closestTimes ])
  
  // This only serves changes in currentSelector
  useEffect(() => {
    if (
      currentFilter === "day" &&
      currentSelector?.day?.id
    ) {
      fetchDayData(currentSelector.day.id)
    }
    else if (
      currentFilter === "week" &&
      currentSelector?.week?.id
    ) {
      fetchWeekData(currentSelector.week.id)
    }
    else if (
      currentFilter === "month" &&
      currentSelector?.month?.id
    ) {
      fetchMonthData(currentSelector.month.id)
    }
  // eslint-disable-next-line
  }, [ currentSelector, currentFilter, chosenProfile ])

  return <div
    style={{ 
      flexDirection: "column" , 
      display: currentTab === "top_stacks" ? "flex" : "none" 
    }}
  >
    <Settings
      currentTab={currentTab}
      setCurrentFilter={setCurrentFilter}
      currentFilter={currentFilter} 
      setCurrentSelector={setCurrentSelector}
      currentSelector={currentSelector}
    />

    <div className="Progress">
      <div className="ProgressChart">
        {
          loading && 
          <div className="ProgressLoading">
            <img src={loadingBarsIcon} alt="" />
            <span>Loading Chart ...</span>
          </div>
        }
        {
          !loading &&
          topStacksData?.length > 0 && 
          <VictoryChart
            domainPadding={{ x: 18 }}
            padding={{ top: 0, left: 45, right: 45, bottom: 45 }}
            width={360} height={153}
          >
            <VictoryBar 
              style={{
                data: { fill: "#c43a31" }
              }}
              barRatio={0.9}
              data={topStacksData} 
            />
            <VictoryAxis
              label="Stacks"
              style={{
                axisLabel: { fontSize: 9, padding: 33 },
                tickLabels: {fontSize: 6, padding: 3 }
              }}
              tickLabelComponent={<VictoryLabel angle={-27} textAnchor="end" verticalAnchor="middle"  />}
            />
            <VictoryAxis 
              dependentAxis
              tickFormat={() => ""}
              label="Interest Level"
              style={{
                axisLabel: { fontSize: 9, padding: 9 },
                tickLabels: {fontSize: 12, padding: 3 }
              }}
            />
          </VictoryChart>
        }
        {
          !loading &&
          topStacksData?.length === 0 &&
          <div style={{ fontSize: 21, fontWeight: 'bold' }}>🤣 No Data Available</div>
        }
      </div>
    </div>
  </div>
}

function TermLabel(props) {
  const { x, y, datum, data } = props
  const rotationPoint = `${x},${y}`
  const currentData = data[datum-1]

  return (
    <g transform={`rotate(-27, ${rotationPoint})`}>
      <text x={x} y={y} textAnchor="end" fontSize={6}>
        {currentData.x}
      </text>
      <text x={x} y={y + 5} textAnchor="end" fontSize={5} fill="grey">
        {currentData.subtitle}
      </text>
    </g>
  );
}

function StacksTrend(props) {
  const { currentTab, chosenProfile } = props
  const [ loading, setLoading ] = useState(true)
  const [ stacksTrendType, setStacksTrendType ] = useState("interested")
  const [ stacksList, setStacksList ] = useState([])
  const [ stacksTrendData, setStacksTrendData ] = useState([])
  const [ currentSelector, setCurrentSelector ] = useState()
  const [ currentFilter, setCurrentFilter ] = useState("week")
  const [ currentStack, setCurrentStack ] = useState()

  // based on accumulated term scores within a stack
  async function fetchPopularStacks() {
    if (!loading) { setLoading(true) }
    const data = await testPopularStacks()
    setStacksList(data)
    setLoading(false)
  }

  // stacks that have zero or close to zero term scores
  async function fetchUnexploredStacks() {
    if (!loading) { setLoading(true) }
    const data = await testUnexploredStacks()
    setStacksList(data)
    setLoading(false)
  }

  async function fetchWeekData(stackId) {
    if (!loading) { setLoading(true) }
    const data = await testTrendData(stackId, "week")
    setStacksTrendData(data)
    setLoading(false)
  }

  async function fetchMontData(stackId) {
    if (!loading) { setLoading(true) }
    const data = await testTrendData(stackId, "month")
    setStacksTrendData(data)
    setLoading(false)
  }
  
  useEffect(() => {
    if (stacksTrendType === "interested") {
      fetchPopularStacks()
    }
    else if (stacksTrendType === "unexplored") {
      fetchUnexploredStacks()
    }
  // eslint-disable-next-line
  }, [ stacksTrendType, chosenProfile ])

  useEffect(() => {
    if (currentStack?.stack_id) {
      if (currentFilter === "week") {
        fetchWeekData(currentStack.stack_id)
      }
      else if (currentFilter === "month") {
        fetchMontData(currentStack.stack_id)
      }
    }
  // eslint-disable-next-line
  }, [ currentStack, currentFilter, chosenProfile ])

  return <div
    style={{ 
      flexDirection: "column" , 
      display: currentTab === "stacks_trend" ? "flex" : "none" 
    }}
  >
    <Settings
      currentTab={currentTab}
      setCurrentFilter={setCurrentFilter}
      currentFilter={currentFilter} 
      setCurrentSelector={setCurrentSelector}
      currentSelector={currentSelector}
      setStacksTrendType={setStacksTrendType}
      stacksTrendType={stacksTrendType}
      setCurrentStack={setCurrentStack}
      currentStack={currentStack}
    />

    <div className="Progress">
      <div className="ProgressChart">
        {
          !currentStack && 
          <div className="ProgressStacksList">
            {
              !loading &&
              stacksList.map((stack, key) => {
                return <div
                  key={key}
                  className="ProgressStacksListItem"
                  onClick={() => { 
                    if (stacksTrendType === "interested") {
                      setCurrentStack(stack) 
                    }
                  }}
                >
                  <div style={{ display: 'flex', flexGrow: 1 }}>
                    {stack.name}
                  </div>
                  {
                    stacksTrendType === "interested" &&
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      See Trend <div className="chevron-right"></div>
                    </div>
                  }
                </div>
              })
            }
            {
              loading &&
              <div className="ProgressLoading">
                <img src={loadingBarsIcon} alt="" />
                <span>Loading Chart ...</span>
              </div>
            }
          </div>
        }
        {
          currentStack && 
          <>
            {
              loading && 
              <div className="ProgressLoading">
                <img src={loadingBarsIcon} alt="" />
                <span>Loading Chart ...</span>
              </div>
            }
            {
              !loading &&
              stacksTrendData?.length > 0 && 
              <VictoryChart
                domainPadding={{ x: 18 }}
                padding={{ top: 15, left: 27, right: 9, bottom: 45 }}
                width={396} height={153}
              >
                <VictoryLabel 
                  x={198} 
                  y={3} 
                  textAnchor="middle"
                  style={{fontSize: 6}}
                  text={`Interest in ${currentStack.name} over the past ${stacksTrendData.length} ${currentFilter}s`}
                />
                <VictoryLine
                  style={{
                    data: { stroke: "#c43a31", strokeWidth: 1 },
                  }}
                  data={stacksTrendData} 
                />
                <VictoryAxis
                  label={currentFilter === "week" ? "Week" : "Month"}
                  style={{
                    axisLabel: { fontSize: 9, padding: 33 },
                    tickLabels: {fontSize: 4, padding: 3 },
                    grid: {
                      stroke: "#ccc",
                      strokeWidth: 0.5,
                      strokeDasharray: "5,5"
                    }
                  }}
                  tickLabelComponent={<VictoryLabel angle={-27} textAnchor="end" verticalAnchor="middle"  />}
                />
                <VictoryAxis 
                  dependentAxis
                  tickFormat={() => ""}
                  label="Interest Level"
                  style={{
                    axisLabel: { fontSize: 9, padding: 9 },
                    tickLabels: {fontSize: 12, padding: 3 }
                  }}
                />
              </VictoryChart>
            }
            {
              !loading &&
              stacksTrendData?.length === 0 &&
              <div style={{ fontSize: 21, fontWeight: 'bold', textAlign: 'center' }}>🤣 No Data Available</div>
            }
          </>
        }
      </div>
    </div>
  </div>
}

function TopTerms(props) {
  const { currentTab, closestTimes, chosenProfile } = props
  const [ loading, setLoading ] = useState(true)
  const [ topTermsData, setTopTermsData ] = useState([])
  const [ currentSelector, setCurrentSelector ] = useState()
  const [ currentFilter, setCurrentFilter ] = useState("week")

  async function fetchWeekData(week) {
    setLoading(true)
    const data = await testWeekTerms(week)
    setTopTermsData(data)
    setLoading(false)
  }

  async function fetchMonthData(month) {
    setLoading(true)
    const data = await testMonthTerms(month)
    setTopTermsData(data)
    setLoading(false)
  }

  useEffect(() => {
    if (
      closestTimes?.week &&
      closestTimes?.month
    ) {
      setCurrentSelector({ 
        week: {
          id: closestTimes?.week,
          name: getFullWeek(closestTimes?.week),
        },
        month: {
          id: closestTimes?.month,
          name: getFullMonth(closestTimes?.month),
        }
      })
    }
  // eslint-disable-next-line
  }, [ closestTimes ])

  useEffect(() => {
    if (
      currentFilter === "week" &&
      currentSelector?.week?.id
    ) {
      fetchWeekData(currentSelector.week.id)
    }
    else if (
      currentFilter === "month" &&
      currentSelector?.month?.id
    ) {
      fetchMonthData(currentSelector.month.id)
    }
  // eslint-disable-next-line
  }, [ currentSelector, currentFilter, chosenProfile ])

  return <div
    style={{ 
      flexDirection: "column" , 
      display: currentTab === "top_terms" ? "flex" : "none" 
    }}
  >
    <Settings
      currentTab={currentTab}
      setCurrentFilter={setCurrentFilter}
      currentFilter={currentFilter} 
      setCurrentSelector={setCurrentSelector}
      currentSelector={currentSelector}
    />

    <div className="Progress">
      <div className="ProgressChart">
        {
          loading && 
          <div className="ProgressLoading">
            <img src={loadingBarsIcon} alt="" />
            <span>Loading Chart ...</span>
          </div>
        }
        {
          !loading &&
          topTermsData?.length > 0 && 
          <VictoryChart
            domainPadding={{ x: 18 }}
            padding={{ top: 0, left: 45, right: 45, bottom: 45 }}
            width={396} height={153}
          >
            <VictoryBar 
              style={{
                data: { fill: "#c43a31" }
              }}
              barRatio={0.9}
              data={topTermsData} 
            />
            <VictoryAxis
              label="Terms"
              style={{
                axisLabel: { fontSize: 9, padding: 33 },
                tickLabels: {fontSize: 6, padding: 3 }
              }}
              // tickLabelComponent={<VictoryLabel angle={-27} textAnchor="end" verticalAnchor="middle"  />}
              tickLabelComponent={<TermLabel data={topTermsData} />}
              tickValues={topTermsData.map(d => d.x)}
              tickFormat={topTermsData.map(d => d.x)}
            />
            <VictoryAxis 
              dependentAxis
              tickFormat={() => ""}
              label="Interest Level"
              style={{
                axisLabel: { fontSize: 9, padding: 9 },
                tickLabels: {fontSize: 12, padding: 3 }
              }}
            />
          </VictoryChart>
        }
        {
          !loading &&
          topTermsData?.length === 0 &&
          <div style={{ fontSize: 21, fontWeight: 'bold', textAlign: 'center' }}>🤣 No Data Available</div>
        }
      </div>
    </div>
  </div>
}

function Progress() {
  // eslint-disable-next-line
  const [ profiles, currentProfile ] = useOutletContext()
  // const { supabase } = useAuth()
  const [ chosenProfile, setChosenProfile ] = useState(currentProfile)
  const [ currentTab, setCurrentTab ] = useState("top_stacks") // top_stacks | stacks_trend | top_terms
  const [ closestTimes, setClosestTimes ] = useState()

  async function fetchClosestTimes() {
    const data = await testClosestTimes()
    setClosestTimes(data)
  }

  // TODO: need to test this
/*
  async function fetchTopStacks(type="day", d) {
    axios.get(
      `${process.env.REACT_APP_TRACK_API_ENDPOINT}/topstacks`,
      {
        type,
        d,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${supabase.realtime.accessToken}`
        }
      }
    )
  }
*/

  useEffect(() => {
    fetchClosestTimes()
  // eslint-disable-next-line
  }, [])

  return (
    <div className="App">
      <div className="AppBg"></div>
      <div id="ProgressBody">
        <div className="ProgressContent">

          <div className="ProgressHeader">
            <h3>Progress</h3>
            
            <div className="ProgressProfile">
              <label for="profile">Profile</label>
              <select 
                name="profile"
                value={chosenProfile} 
                onChange={(e) => { setChosenProfile(e.target.value) }}
              >
                {
                  profiles.map((profile, key) => (
                    <option key={key} value={profile.id}>{profile.name}</option>
                  ))
                }
              </select>
            </div>
          </div>

          <div className="ProgressTabs">
            <div 
              onClick={ () => { setCurrentTab("top_stacks") } }
              className={`${ currentTab === "top_stacks" ? "ProgressTab CurrentProgressTab" : "ProgressTab" }`}
            >
              Top Stacks
            </div>
            <div 
              onClick={ () => { setCurrentTab("stacks_trend") } }
              className={`${ currentTab === "stacks_trend" ? "ProgressTab CurrentProgressTab" : "ProgressTab" }`}
            >
              Stacks Trend
            </div>
            <div 
              onClick={ () => { setCurrentTab("top_terms") } }
              className={`${ currentTab === "top_terms" ? "ProgressTab CurrentProgressTab" : "ProgressTab" }`}
            >
              Top Terms
            </div>
          </div>
          
          <TopStacks 
            chosenProfile={chosenProfile}
            currentTab={currentTab}
            closestTimes={closestTimes}
          />

          <StacksTrend
            chosenProfile={chosenProfile}
            currentTab={currentTab}
          />

          <TopTerms
            chosenProfile={chosenProfile}
            currentTab={currentTab}
            closestTimes={closestTimes}
          />
        </div>
      </div>
    </div>
  )
}

export default Progress