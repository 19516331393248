import React, { createContext, useContext, useMemo } from "react"
import { loadStripe } from "@stripe/stripe-js"

const StripeContext = createContext()

export const useStripePromise = () => {
  const context = useContext(StripeContext)
  if (!context) {
    throw new Error('useStripePromise must be used within a StripeProvider')
  }
  return context
}

export const StripeProvider = ({ children }) => {
  const stripePromise = useMemo(() => loadStripe(process.env.REACT_APP_STRIPE_PK_KEY), [])
  
  return (
    <StripeContext.Provider value={stripePromise}>
      {children}
    </StripeContext.Provider>
  )
}