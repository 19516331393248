function Loading() {
  return (
    <div className="AppLoading">
      <div className="App">
        <nav className="Navbar">
          <div className="Logo"><a href="/">STACKCOCO</a></div>
        </nav>
      </div>
    </div>
  );
}

export default Loading;
