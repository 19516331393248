import '../styles/Payment.css'

import { useState, useEffect } from 'react'
import { useAuth } from "../context/AuthProvider"
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { useLocation } from "react-router-dom"
import { useStripePromise } from '../context/StripeProvider'
import axios from 'axios'

function CardForm(props) {
  const { source } = props
  const stripe = useStripe()
  const elements = useElements()
  const [errorMessage, setErrorMessage] = useState(null)

  async function handleSubmit(event) {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null
    }

    const urlSource = source ? source : "p/payment/manage"

    const return_url = process.env.NODE_ENV === "development" ?
      `http://192.168.1.121:3001/${urlSource}` :
      `https://stackcoco.com/${urlSource}`

    const {error} = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url,
      },
    })

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  useEffect(() => {
  // eslint-disable-next-line
  }, [])

  return <div className="AddPaymentForm">
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div>
        <button type="submit" disabled={!stripe} className="ActionButton">
          Add Payment Method
        </button>
        {errorMessage && <div>{errorMessage}</div>}
      </div>
    </form>
  </div>
}

function AddPayment() {
  const { supabase } = useAuth()
  const location = useLocation()
  const stripePromise = useStripePromise()
  const [ clientSecret, setClientSecret ] = useState(null)
  
  const source = location.state.source

  async function createSetupIntent() {
    const r = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/v1/stripe/create-setup-intent`,
      {},
      { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
    )
    setClientSecret(r.data.data)
  }

  useEffect(() => {
    createSetupIntent()
  // eslint-disable-next-line
  }, [])

  const options = {
    clientSecret,
  }

  return <div className="App">
    <div className="AppBg"></div>
    <div id="PaymentBody">
      <div className="PaymentContent">
        {
          clientSecret && <Elements stripe={stripePromise} options={options}>
            <CardForm 
              source={source}
              clientSecret={clientSecret} 
            />
          </Elements>
        }
      </div>
    </div>
  </div>
}

export default AddPayment