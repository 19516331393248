import '../styles/Payment.css'
import { useState, useEffect } from 'react'
import axios from 'axios'
import {
  useStripe, 
  useElements, 
  Elements, 
  PaymentElement,
} from '@stripe/react-stripe-js'
import { useLocation } from "react-router-dom"
import { useAuth } from "../context/AuthProvider"
import { useStripePromise } from '../context/StripeProvider'
import wedgesIcon from "../assets/wedges.svg"

function CheckoutForm(props) {
  const { currentPlan } = props
  const [ processing, setProcessing ] = useState(false)

  const stripe = useStripe()
  const elements = useElements()
  const price = currentPlan?.price ? currentPlan?.price / 100.0 : null

  async function handleSubmit(e) {
    setProcessing(true)

    localStorage.setItem("stackcoco_last_refresh_mp", currentPlan?.position)

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    const return_url = process.env.NODE_ENV === "development" ?
      `http://192.168.1.121:3001/p/subscribe/complete` :
      `https://stackcoco.com/p/subscribe/complete`

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: { return_url },
    })

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message)
      setProcessing(false)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  return <div className="PaymentForm">
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button className="ActionButton" disabled={!stripe || processing}>
        { processing ? "Processing ..." : price ? `Pay $${price} Now` : `Pay Now` }
      </button>
    </form>
  </div>
}

function Payment(props) {
  const { supabase } = useAuth()
  const location = useLocation()
  const stripePromise = useStripePromise()
  const [ clientSecret, setClientSecret ] = useState(null)

  const currentPlan = location?.state.plan

  async function initSubscription() {
    try {
      const url = `${process.env.REACT_APP_API_ENDPOINT}/v1/stripe/checkout`
      const r = await axios.post(
        url,
        { 
          product_id: currentPlan.id,
        },
        { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
      )
      setClientSecret(r.data.clientSecret)
    }
    catch (e) {

    }
  }

  useEffect(() => {
    initSubscription()
  // eslint-disable-next-line
  }, [])

  const options = {
    clientSecret,
    // appearance: {},
  }

  return (
    <div className="App">
      <div className="AppBg"></div>
      <div id="PaymentBody">
        <div className="PaymentContent">
          {
            clientSecret && <Elements stripe={stripePromise} options={options}>
              <CheckoutForm 
                currentPlan={currentPlan}
                { ...props }
              />
            </Elements>
          }
          {
            !clientSecret && 
            <div className="Loading">
              <img src={wedgesIcon} width={200} height={200} alt="" />
              <span>Loading Secure Payment Form ...</span>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default Payment