import '../styles/Onboarding.css';
import ProfileForm from '../components/ProfileForm';

function Onboarding() {
  return (
    <div className="App">
      <div className="Action">
        <div className="Onboarding">
          <ProfileForm />
        </div>
      </div>
    </div>
  );
}

export default Onboarding;
