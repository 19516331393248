import '../styles/App.css';
import Navbar from './Navbar';
import UserAuthForm from './UserAuthForm';
import { useParams } from 'react-router-dom';

function UserAuthPage() {
  const { action } = useParams();
  return (
    <div className="App">
      <Navbar page={`/auth/${action}`} />
      <div className="Action">
        <UserAuthForm action={action} />
      </div>
    </div>
  );
}

export default UserAuthPage;
