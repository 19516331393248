import '../styles/Settings.css'

import { useState  } from 'react'
import axios from 'axios'
import { useAuth } from "../context/AuthProvider"
import { Link } from "react-router-dom"
import { oneYearAgo, formatDate } from "../common/utils"

const BLOCK_DOWNGRADE_OR_CANCEL_DAYS = 27

function isWithinBlockedDays(date) {
  const now = new Date();
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000

  // Calculate the difference between the given date and the current date
  const difference = Math.abs(now - date)

  // Check if the difference is less than or equal to BLOCK_DOWNGRADE_OR_CANCEL_DAYS days in milliseconds
  return difference <= BLOCK_DOWNGRADE_OR_CANCEL_DAYS * oneDayInMilliseconds
}

function Account(props) {
  const { isSubscribed } = props
  const { supabase, user } = useAuth()
  const [updateEmailOpen, setUpdateEmailOpen] = useState(false)
  const [updatePasswordOpen, setUpdatePasswordOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState(null)

  const updateEmail = async () => {
    if (!email) {
      setError("Invalid email")
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/users/update-email`,
        {
          email,
        },
        { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
      )
      setUpdateEmailOpen(false)
    }
    catch (err) {

    }
  }

  const updatePassword = async () => {
    if (newPassword !== confirmPassword) {
      setError("New and Confirm Password do not match")
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/users/update-password`,
        {
          currentPassword,
          newPassword,
          confirmPassword,
        },
        { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
      )
      setUpdatePasswordOpen(false)
    }
    catch (err) {

    }
  }

  return <div className="Account">
    <h3>Account</h3>
    <div>
      <div>
        <h4>Email: <span>{user.email}</span></h4>
      </div>
      {
        error && <div style={{ color: 'red', margin: '9px 0' }}>{error}</div>
      }
      {
        // Disabled for MVP
        // !updateEmailOpen && <div>
        //   <button onClick={ () => { setUpdateEmailOpen(true) }}>Change Email</button>
        // </div>
      }
      {
        updateEmailOpen && <div>
          <div>
            <input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="New Email" />
          </div>
          <div>
            <button onClick={() => { setUpdateEmailOpen(false) }}>Cancel</button>
            <button onClick={updateEmail}>Update Email</button>
          </div>
        </div>
      }
    </div>
    <div>
      <h4>Password</h4>
      {
        !updatePasswordOpen && <div>
          <button onClick={ () => { setUpdatePasswordOpen(true) }}>Change Password</button>
        </div>
      }
      {
        updatePasswordOpen && <>
          <div>
            <input 
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Current Password"
            />
          </div>
          <div>
            <input 
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="New Password"
            />
          </div>
          <div>
            <input 
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm New Password"
            />
          </div>
          <div>
            <button onClick={() => { setUpdatePasswordOpen(false) }}>Cancel</button>
            <button onClick={updatePassword}>Update Password</button>
          </div>
        </>
      }
    </div>
    {
      isSubscribed && <div className="PaymentMethod">
        <h3>Payment Method</h3>
        {/* Payment Methods - list / add / remove / change default */}
        <Link to={`/p/payment/manage`}>
          Manage Payment Method
        </Link>
      </div>
    }
  </div>
}

function Subscription(props) {
  const { user } = useAuth()
  const expiryDate = new Date(user.user_metadata.subscribed)
  const endDate = formatDate(expiryDate)
  const startDate = formatDate(oneYearAgo(expiryDate))

  return <div className="Subscription">
    <h3>Subscription</h3>
    
    <h4>Maximum Profiles Supported: {user.user_metadata.max_profiles}</h4>
    
    <h4>Subscription Period</h4>
    <div className="SubscriptionPeriod">
      <div>{startDate} to {endDate}</div>
    </div>
    
    <h4>Manage Subscription</h4>
    <div className="SubscriptionManage">
      <div className="SubscriptionManageItem">
        <Link
          to={`/p/subscribe/upgrade`} 
        >
          Upgrade Subscription
        </Link>
      </div>
      {
        user?.user_metadata?.max_profiles > 1 && 
        <div className="SubscriptionManageItem">
          {
            (
              user?.user_metadata?.last_downgraded &&
              isWithinBlockedDays(new Date(user.user_metadata.last_downgraded))
            ) ?
            <button onClick={ () => { alert("An unexpected error occurred. For assistance, please reach out to our support team at hi@stackcoco.com.") }}>Downgrade Subscription</button> :
            <Link
              to={`/p/subscribe/downgrade`} 
            >
              Downgrade Subscription
            </Link>
          }
        </div>
      }
      <div className="SubscriptionManageItem">
        {
          (
            user?.user_metadata?.last_cancelled &&
            isWithinBlockedDays(new Date(user.user_metadata.last_cancelled))
          ) ?
          <button onClick={ () => { alert("An unexpected error occurred. For assistance, please reach out to our support team at hi@stackcoco.com.") }}>Cancel Subscription</button> :
          <Link
            to={`/p/subscribe/cancel`} 
          >
            Cancel Subscription
          </Link>
        }
      </div>
    </div>
  </div>
}

function Settings(props) {
  const { isSubscribed } = useAuth()
  
  return <div className="App">
    <div className="AppBg"></div>
    <div id="SettingsBody">
      <div className="SettingsContent">
        <div className="SettingsContentTab">
          <Account isSubscribed={isSubscribed} />
        </div>
        <div className="SettingsContentTab">
        {
          isSubscribed && <Subscription />
        }
        </div>
      </div>
    </div>
  </div>
}

export default Settings