import '../styles/Profiles.css'
import axios from 'axios'
import { useState, useEffect } from 'react'
import { useOutletContext, useNavigate, useLocation } from "react-router-dom"
import { useAuth } from "../context/AuthProvider"
import { track } from "../common/track"
import editIcon from "../assets/editing.png"
import CloseIcon from '../assets/cancel.png'

function endDate() {
  const today = new Date()
  const lastYear = new Date(today)
  lastYear.setFullYear(today.getFullYear() - 1)

  const mm = String(lastYear.getMonth() + 1).padStart(2, '0')
  const dd = String(lastYear.getDate()).padStart(2, '0')
  const yyyy = lastYear.getFullYear()

  return `${yyyy}-${mm}-${dd}`
}

function toUrl(id) {
  return `https://stackco.co/badges/${id}.jpg`
}

function ChangeAvatars(props) {
  const { supabase, currentProfile, selectAvatar, setSelectAvatar } = props
  const [ avatars, setAvatars ] = useState([])
  const [ fetchMore, setFetchMore ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ nextCursor, setNextCursor ] = useState(null)

  useEffect(() => {
    fetchAvatars(true)

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (nextCursor && fetchMore && !loading) {
      fetchAvatars()
    }

    // eslint-disable-next-line
  }, [fetchMore, loading])

  useEffect(() => {
    const avatarsBody = document.getElementById('AvatarsBody')
    const handleScroll = async () => {
      const scrollTop = avatarsBody.scrollTop
      const scrollHeight = avatarsBody.scrollHeight
      const offsetHeight = avatarsBody.offsetHeight
      const contentHeight = scrollHeight - offsetHeight

      // Set FetchMore state to true when users scrolled almost to the bottom
      if (scrollTop > contentHeight * 0.8) {
        setFetchMore(true)
      }
    }
    avatarsBody.addEventListener('scroll', handleScroll)

    return () => avatarsBody.removeEventListener('scroll', handleScroll)
  // eslint-disable-next-line
  }, [])

  async function fetchAvatars(reset) {
    console.log("FETCH AVATARS .............")
    setFetchMore(false)
    setLoading(true)
    const limit = nextCursor ? process.env.REACT_APP_MAX_PROFILE : 18
    let url = `${process.env.REACT_APP_API_ENDPOINT}/v1/badges?first=${limit}&is_avatar=t`
    if (!reset && nextCursor) { url += `&after=${nextCursor}` }
    let data = [], next_cursor = null
    try {
      const r = await axios.get(url, { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } })
      data = r?.data?.data
      next_cursor = r?.data?.next_cursor
      track({ supabase, profile: currentProfile, p: { event: "find_avatars", data: data?.length, after: Boolean(nextCursor), reset, } })
    }
    catch (e) {
      track({ supabase, profile: currentProfile, t: 'e', p: { event: "find_avatars", after: Boolean(nextCursor), reset, e: e?.message } })
    }
    if (reset) { setAvatars(data) }
    else { setAvatars((prevAvatars) => [ ...prevAvatars, ...data ]) }
    setNextCursor(next_cursor)
    setLoading(false)
  }

  return <div id="AvatarsBody">
    {
      avatars.map((badge, key) => (
        <div 
          key={key} 
          className={`${ selectAvatar?.id === badge.id ? "AvatarSelected " : "" }Avatar` }
          onClick={ () => {
            track({ supabase, profile: currentProfile, p: { event: "select_avatar", badge } })
            setSelectAvatar(badge)
          }}
        >
          <img src={toUrl(badge.id)} alt="" />
        </div>
      ))
    }
  </div>
}

function Modal({ 
  showModal, 
  setShowModal,
  removeProfile,
  defaultEditProfile,
  currentProfile,
  setCurrentProfile,
  setRefreshProfiles,
  activeProfiles,
  setAvatarUrl,
  setEditProfile,
}) {
  const { supabase } = useAuth()
  const [ password, setPassword ] = useState(null)
  const [ error, setError ] = useState(null)

  async function handleRemoveProfile() {
    const r = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/v1/users/verify-password`,
      {
        password,
      },
      { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
    )

    if (r.error) {
      setError("Invalid Password")
    }
    else {
      await removeProfile({ profile_id: defaultEditProfile.id })

      // Switch to another active profile in case where the user's disabling the current profile
      if (currentProfile === defaultEditProfile.id) {
        setCurrentProfile(activeProfiles.filter(p => p.id !== defaultEditProfile.id)?.[0]?.id)
      }

      setShowModal(false)
      setAvatarUrl(null)
      setEditProfile(false) 
      setRefreshProfiles(new Date().getTime())
    }
  }

  if (!showModal) return null

  return <div className="ModalOverlay">
    <div className="ModalContent" style={{ height: 162 }}>
      <div className="ModalHeader">
        <h3>Verify Password</h3>
        <button
          className="CloseButton"
          onClick={() => setShowModal(false)}
          style={{ marginTop: 6 }}
        >
          <img src={CloseIcon} alt="close" style={{ width: 16, height: 16 }} />
        </button>
      </div>
      <div>
        <input 
          type="password" 
          placeholder="Password" 
          value={password} onChange={ e => {
            setPassword(e.target.value) 
          }}
          style={{ 
            padding: '9px 18px',
            borderRadius: 9,
            border: '1px solid black',
            width: 'calc(100% - 36px)',
            fontSize: 15,
            marginBottom: -9,
          }}
        />
        {
          error && <div style={{ color: 'red', marginTop: 18, marginBottom: -9 }}>{error}</div>
        }
        <button
          onClick={ async () => {
            await handleRemoveProfile()
          }}
          className="ActionButton"
          disabled={!password}
        >
          Confirm Remove Profile
        </button>
      </div>
    </div>
  </div>
}

function Profiles() {
  // eslint-disable-next-line
  const [ profiles, currentProfile, setCurrentProfile, setRefreshProfiles ] = useOutletContext()
  const { supabase, isSubscribed, user } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const [ editProfile, setEditProfile ] = useState()
  const [ name, setName ] = useState()
  const [ birthday, setBirthday ] = useState()
  const [ avatarUrl, setAvatarUrl ] = useState()
  const [ changeAvatar, setChangeAvatar ] = useState()
  const [ selectAvatar, setSelectAvatar ] = useState()
  const [ showModal, setShowModal ] = useState(false)
  
  const activeProfiles = profiles.filter(p => p.active)
  const defaultEditProfile = editProfile ? activeProfiles.find(p => p.id === editProfile) : null
  const isEdit = location.pathname.includes('edit')
  const maxProfiles = user?.user_metadata?.max_profiles
  
  async function patchProfile({ profile_id, name, birthday, image_url }) {
    let url = `${process.env.REACT_APP_API_ENDPOINT}/v1/users/update-profile`
    await axios.post(
      url,
      {
        profile_id,
        name,
        birthday,
        image_url,
      },
      { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
    )
  }

  async function disableProfile({ profile_id }) {
    let url = `${process.env.REACT_APP_API_ENDPOINT}/v1/users/disable-profile`
    await axios.post(
      url,
      {
        profile_id
      },
      { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
    )
  }

  async function removeProfile({ profile_id }) {
    let url = `${process.env.REACT_APP_API_ENDPOINT}/v1/users/remove-profile`
    await axios.post(
      url,
      {
        profile_id
      },
      { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
    )
  }

  async function activateProfile({ profile_id }) {
    let url = `${process.env.REACT_APP_API_ENDPOINT}/v1/users/activate-profile`
    await axios.post(
      url,
      {
        profile_id
      },
      { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
    )
  }

  useEffect(() => {
    if (selectAvatar) {
      if (selectAvatar.id) {
        setAvatarUrl(toUrl(selectAvatar.id))
      }
      else if (selectAvatar.url) {
        setAvatarUrl(selectAvatar.url)
      }
    }
  // eslint-disable-next-line
  }, [ selectAvatar ])

  return (
    <div className="App">
      <Modal 
        showModal={showModal} 
        setShowModal={setShowModal} 
        removeProfile={removeProfile}
        defaultEditProfile={defaultEditProfile}
        currentProfile={currentProfile}
        setCurrentProfile={setCurrentProfile}
        setRefreshProfiles={setRefreshProfiles}
        activeProfiles={activeProfiles}
        setAvatarUrl={setAvatarUrl}
        setEditProfile={setEditProfile}
      />
      <div className="Action">
        <div className="EditProfile">
          {
            !editProfile && <>
              <div className="Profiles">
                <h2>{isEdit ? "Manage Profiles" : "Choose a Profile"}</h2>
              </div>
              <div className="ProfileTabs">
                {
                  profiles.map((profile, key) => <div 
                    key={key} 
                    className="ProfileTab"
                    onClick={ async () => { 
                      // In case where profile is active
                      if (profile.active) {
                        if (isEdit) {
                          setEditProfile(profile.id)
                        }
                        else {
                          setCurrentProfile(profile.id)
                          navigate('/p/stacks') 
                        }
                      }
                      // In case where profile is disabled
                      else {
                        if (isEdit) {
                          if (maxProfiles >= profiles.length) {
                            if (window.confirm(`Confirm to activate ${profile.name}'s profile?`)) {
                              await activateProfile({ profile_id: profile.id })
                              setAvatarUrl(null)
                              setEditProfile(false) 
                              setRefreshProfiles(new Date().getTime())
                            }
                          }
                          else {
                            navigate('/p/stacks/subscribe',{ state: { action: "upgrade" } })
                          }
                        }
                      }
                    } }
                  >
                    <div className="ProfileImageContainer">
                      <img src={profile.image_url} width={108} alt="" />
                      {
                        isEdit && 
                        profile.active &&
                        <div class="EditIconOverlay">
                          <img src={editIcon} alt="" /> <span>Edit</span>
                        </div>
                      }
                      {
                        !profile.active &&
                        <div class="EditIconOverlay">
                          <span style={{ color: 'gray' }}>Disabled</span>
                        </div>
                      }
                    </div>
                    <div className="ProfileTabName" style={{ color: !profile.active ? 'gray' : undefined }}>{profile.name}</div>
                  </div>)
                }
              </div>
            </>
          }
          {
            editProfile && <>
              {
                !changeAvatar && <>
                  <div className="Close">
                    <button onClick={ e => { setAvatarUrl(null); setEditProfile(false) } }>
                      <img src={CloseIcon} width="18" alt="close" />
                    </button>
                  </div>
                    <h3>Edit Profile</h3>
                    <div className="EditProfileBody" style={!isSubscribed ? { marginBottom: 0 } : {}}>
                      {/* Avatar */}
                      <div className="AvatarTab">
                        <div className="CurrentAvatar">
                          <img src={ avatarUrl ? avatarUrl : defaultEditProfile?.image_url} alt="" />
                        </div>
                        <div className="CurrentAvatarButton">
                          <button onClick={ e => { setChangeAvatar(true) }}>Change Avatar</button>
                        </div>
                      </div>
                      <div>
                        {/* Name */}
                        <div className="InputTab">
                          <input 
                            type="text" 
                            placeholder="Name" 
                            defaultValue={defaultEditProfile?.name}
                            onChange={ e => { 
                              setName(e.target.value) } 
                            } 
                          />
                        </div>
                        {/* Birthday */}
                        <div className="InputTab">
                          <input 
                            type="date" 
                            name="birthday" 
                            defaultValue={defaultEditProfile.birthday}
                            value={birthday}
                            onChange={ e => { setBirthday(e.target.value) }} 
                            min="2000-01-01" 
                            max={endDate()} 
                          />
                        </div>
                        {/* Confirm or Cancel */}
                        <div className="Confirm">
                          <button 
                            className="CancelButton"
                            onClick={ e => { 
                              setAvatarUrl(null)
                              setEditProfile(false) 
                            } }
                          >
                            Cancel
                          </button>
                          <button 
                            onClick={ 
                              async e => { 
                                await patchProfile({
                                  profile_id: defaultEditProfile.id,
                                  name,
                                  birthday
                                })

                                setAvatarUrl(null)
                                setEditProfile(false) 
                                setRefreshProfiles(new Date().getTime())
                              } 
                            }>Save</button>
                        </div>
                      </div>
                    </div>

                    {/* Disable Profile */}
                    {
                      isSubscribed && 
                      activeProfiles?.length > 1 &&
                      <div style={{ marginTop: 45 }}>
                        <h4>Disable Profile</h4>
                        <div className="DeleteProfile">
                          <div className="Description">
                            <span>You can enable any disabled profile anytime in the future.</span>
                          </div>
                          <div className="">
                            <button
                              onClick={ async e => {
                                if (window.confirm(`Confirm to disable ${defaultEditProfile.name}'s profile?`)) {
                                  await disableProfile({ profile_id: defaultEditProfile.id })

                                  // Switch to another active profile in case where the user's disabling the current profile
                                  if (currentProfile === defaultEditProfile.id) {
                                    setCurrentProfile(activeProfiles.filter(p => p.id !== defaultEditProfile.id)?.[0]?.id)
                                  }

                                  setAvatarUrl(null)
                                  setEditProfile(false) 
                                  setRefreshProfiles(new Date().getTime())
                                }
                              }}
                            >
                              Disable Profile
                            </button>
                          </div>
                        </div>
                      </div>
                    }

                    {/* Remove Profile */}
                    {
                      isSubscribed && 
                      activeProfiles?.length > 1 &&
                      <div style={{ marginTop: 36 }}>
                        <h4>Remove Profile</h4>
                        <div className="DeleteProfile">
                          <div className="Description">
                            <span><b>NOTE: THIS IS AN IRREVERSIBLE ACTION.</b> <br/>Removing a profile removes everything associated with it (e.g. personalized feed, badges, diamonds, favorites).</span>
                          </div>
                          <div className="">
                            <button
                              onClick={ async e => {
                                setShowModal(true)
                              }}
                              style={{ backgroundColor: "red", color: "white" }}
                            >
                              Remove Profile
                            </button>
                          </div>
                        </div>
                      </div>
                    }
                </>
              }
              {
                changeAvatar && <>
                  <h3>Change Avatar</h3>
                  <div className="ChangeAvatar">
                    <ChangeAvatars 
                      supabase={supabase}
                      currentProfile={currentProfile}
                      selectAvatar={selectAvatar}
                      setSelectAvatar={setSelectAvatar}
                    />
                    {/* Confirm or Cancel */}
                    <div className="Confirm">
                      <button 
                        className="CancelButton"
                        onClick={ e => { 
                          setChangeAvatar(false) 
                          setSelectAvatar({ url: defaultEditProfile?.image_url })
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={ async e => {
                          await patchProfile({
                            profile_id: defaultEditProfile.id,
                            image_url: avatarUrl,
                          })

                          setChangeAvatar(false)
                          setRefreshProfiles(new Date().getTime())
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </>
              }
            </>
          }
        </div>
      </div>
    </div>
  );
}

export default Profiles