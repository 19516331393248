import '../styles/Subscribe.css'
import { useState, useEffect  } from 'react'
import { useOutletContext, useLocation, useNavigate } from "react-router-dom"
import axios from 'axios'
import { useAuth } from "../context/AuthProvider"
import { formatDate } from "../common/utils"
import CommonManagePayment from './common/ManagePayment' 
import cancelIcon from '../assets/cancel.png'
import wedgesIcon from "../assets/wedges.svg"

function Modal({ 
  showModal, 
  setShowModal,
  manageSubscription,
  action, // downgrade | upgrade
}) {
  const { supabase } = useAuth()
  const [ password, setPassword ] = useState(null)
  const [ error, setError ] = useState(null)
  const [ processing, setProcessing ] = useState(false) 

  async function execSubscription() {
    const r = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/v1/users/verify-password`,
      {
        password,
      },
      { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
    )

    if (r.error) {
      setError("Invalid Password")
    }
    else {
      await manageSubscription({ action })
    }
  }

  if (!showModal) return null

  return <div className="ModalOverlay">
    <div className="ModalContent" style={{ height: 162 }}>
      <div className="ModalHeader">
        <h3>Verify Password</h3>
        {
          !processing && <button
            className="CloseButton"
            onClick={() => setShowModal(false)}
            style={{ marginTop: 6 }}
          >
            <img src={cancelIcon} alt="close" style={{ width: 16, height: 16 }} />
          </button>
        }
      </div>
      <div>
        <input 
          type="password" 
          placeholder="Password" 
          value={password} onChange={ e => {
            setPassword(e.target.value) 
          }}
          style={{ 
            padding: '9px 18px',
            borderRadius: 9,
            border: '1px solid black',
            width: 'calc(100% - 36px)',
            fontSize: 15,
            marginBottom: -9,
          }}
        />
        {
          error && <div style={{ color: 'red', marginTop: 18, marginBottom: -9 }}>{error}</div>
        }
        <button
          onClick={ async () => {
            setProcessing(true)
            try { await execSubscription() }
            catch (e) {}
            setProcessing(false)
          }}
          className="ActionButton"
          disabled={!password || processing}
        >
          { processing ? "Processing ..." : `confirm ${action} subscription` }
        </button>
      </div>
    </div>
  </div>
}

function Upgrade(props) {
  const { 
    proratedPrice,
    proratedDays,
    plan,
    manageSubscription,
  } = props

  const { user } = useAuth()
  const [ showModal, setShowModal ] = useState(false)
  const expiryDate = new Date(user.user_metadata.subscribed)
  const endDate = formatDate(expiryDate)

  return <div className="UpgradeDescription">
    <Modal 
      showModal={showModal} 
      setShowModal={setShowModal} 
      manageSubscription={manageSubscription}
      action="upgrade"
    />
    {/* Prorated Invoice Details */}
    <div className="UpgradeTab" style={{ width: '40%' }}>
      <span><b>UPGRADE TO PLAN FOR {plan.position} USERS</b></span>
      <span><label>Upgrade cost (charge immediately)</label><b>{proratedPrice > 0 ? `$${proratedPrice}` : "FREE"} (prorated by day, for {proratedDays} days)</b></span>
      <span><label>Upgrade plan (next yearly billing cycle)</label><b>Renews on {endDate} for ${plan.price / 100} / year</b></span>
    </div>

    <div className="UpgradeTab" style={{ width: '60%' }}>
      <span className="SpecialNotice">Upgraded plan will be effective immediately after payment.</span>

      {
        proratedPrice > 0 && 
        <div className="PaymentMethods">
          <h4>Payment Methods</h4>
          <CommonManagePayment source={`p/subscribe/upgrade`} />
        </div>
      }

      <button
        onClick={ () => {
          setShowModal(true)
        } }
        className="PlanButton"
        style={{ marginTop: -9 }}
      >
        { proratedPrice > 0 ? `pay $${proratedPrice} now` : "confirm upgrade" }
      </button>
    </div>
  </div>
}

function Downgrade(props) {
  const { 
    proratedPrice,
    proratedDays,
    plan, 
    profiles, 
    currentProfile,
    setCurrentProfile,
    setRefreshProfiles,
    manageSubscription,
  } = props
  const { supabase, user } = useAuth()
  const [ step, setStep ] = useState(1)
  const [ showModal, setShowModal ] = useState(false)
  const expiryDate = new Date(user.user_metadata.subscribed)
  const endDate = formatDate(expiryDate)
  const activeProfiles = profiles.filter(p => p.active)
  const maxProfiles = user?.user_metadata?.max_profiles
  const diff = activeProfiles.length - plan.position

  return <div className="DowngradeDescription">
    <Modal 
      showModal={showModal} 
      setShowModal={setShowModal} 
      manageSubscription={manageSubscription}
      action="downgrade"
    />
    {
      step === 1 &&
      <>
        {
          diff > 0 &&
          <div className="DowngradeNoticeList">
            <div className="DowngradeSpecialNotice">
              The plan you are switching to supports only {plan.position} user{ diff > 1 ? "s" : "" }.<br/>
              Please deactivate {diff} profile{ diff > 1 ? "s" : "" } to proceed with the downgrade.
            </div>
            <div className="DowngradeNotice">
              * Remember, you can always reactivate an inactive profile anytime, provided your subscription plan supports the total number of active users.
            </div>
          </div>
        }
        {
          diff <= 0 &&
          <div className="DowngradeNoticeList">
            <div className="DowngradeSpecialNotice">
            Please review the profiles listed below. <br/>Confirm that these are the profiles you would like to keep active following the downgrade.
            </div>
          </div>
        }
        <div className="CurrentProfiles">
          {
            profiles.map((profile, key) => (
              <div
                key={key}
                className="CurrentProfile"
              >
                <label>{profile?.name}</label>
                {
                  <div className="CurrentStatus" style={{ color: profile.active ? 'blue' : 'gray' }}>
                    { profile.active ? "active" : "inactive" }
                  </div>
                }
                {
                  activeProfiles.length > 1 &&
                  profile.active &&
                  <button
                    onClick={ async () => { 
                      if (window.confirm(`Confirm to deactivate ${profile.name}'s profile?`)) {
                        if (currentProfile === profile.id) {
                          const nextProfile = activeProfiles.find(p => p.id !== currentProfile)
                          console.log("nextProfile", nextProfile)

                          if (nextProfile?.id) { setCurrentProfile(nextProfile.id) }
                        }

                        await axios.post(
                          `${process.env.REACT_APP_API_ENDPOINT}/v1/users/disable-profile`,
                          { profile_id: profile.id },
                          { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
                        )

                        setRefreshProfiles(new Date().getTime())
                      }
                    } }
                  >
                    Disable Profile
                  </button>
                }
                {
                  !profile.active &&
                  activeProfiles.length < maxProfiles &&
                  <button
                    onClick={ async () => { 
                      if (window.confirm(`Confirm to activate ${profile.name}'s profile?`)) {
                        await axios.post(
                          `${process.env.REACT_APP_API_ENDPOINT}/v1/users/activate-profile`,
                          { profile_id: profile.id },
                          { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
                        )
                        
                        setRefreshProfiles(new Date().getTime())
                      }
                    } }
                  >
                    Activate Profile
                  </button>
                }
              </div>
            ))
          }
        </div>
        <div style={{ marginTop: -9 }}>
          <button
            onClick={ () => {
              setStep(2)
            } }
            className="PlanButton"
            disabled={diff > 0}
          >
            continue to downgrade
          </button>
        </div>
      </>
    }

    {
      step === 2 &&
      <div className="UpgradeDescription">
        <div className="UpgradeTab" style={{ width: '42%' }}>
          <div><b>DOWNGRADE TO PLAN FOR {plan.position} USER{ diff > 1 ? "S" : "" }</b></div>
          <span><label>Downgrade refund amount</label><b>${proratedPrice} (prorated by day, for {proratedDays} days)</b></span>
          <span><label>Downgrade plan (next yearly billing cycle)</label><b>Renews on {endDate} for ${plan.price / 100} / year</b></span>
          <span>Your refund will be credited to the original payment method within 5 to 10 business days.</span>
        </div>
        
        <div className="UpgradeTab" style={{ width: '58%' }}>
          <span className="SpecialNotice">Downgraded plan will be effective immediately after payment.</span>
          
          <div className="ActiveProfiles">
            <span style={{ cursor: 'pointer' }} onClick={ () => { setStep(1) }}>Active Profiles (edit)</span>
            {
              activeProfiles.map((profile, key) => (
                <div key={key}>
                  <b>{profile?.name}</b>
                </div>
              ))
            }
          </div>

          <button
            onClick={ () => {
              setShowModal(true)
            } }
            className="PlanButton"
          >
            confirm downgrade
          </button>
        </div>
      </div>
    }
  </div>
}

function SubscribeConfirm(props) {
  // eslint-disable-next-line
  const [ profiles, currentProfile, setCurrentProfile, setRefreshProfiles ] = useOutletContext()
  const { supabase } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const [ proratedPrice, setProratedPrice ] = useState(null)
  const [ proratedDays, setProratedDays ] = useState(null)
  const [ loading, setLoading ] = useState(true)
  
  const plan = location.state.plan
  const type = location.state.type

  async function manageSubscription({ action, type }) {
    const r = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/v1/stripe/manage-subscriptions`,
      {
        action,
        type: action === "upcoming" ? type : undefined,
        plan,
      },
      { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
    )

    if (action === "upcoming") {
      setProratedPrice(r.data?.data?.proratedAmount / 100)
      setProratedDays(r.data?.data?.proratedDays)
      setLoading(false)
    } 
    else {
      const actionParam = action === "upgrade" ? "upgrading" : "downgrading"
      localStorage.setItem("stackcoco_last_refresh_mp", r.data?.data?.maxProfiles)
      navigate(`/p/subscribe/complete?action=${actionParam}`)
    } 
  }

  useEffect(() => {
    manageSubscription({ action: "upcoming", type })

    return () => {
    }
  // eslint-disable-next-line
  }, [])

  return <div className="App">
    <div className="AppBg"></div>
    <div id="SubscribeBody">
      <div className="SubscribeContent">
        {
          loading && <div className="Loading">
            <img src={wedgesIcon} alt="" width={200} height={200} />
            <span>Loading Plan Details ...</span>
          </div>
        }
        {
          !loading &&
          <div className="PlanContent">
            {/* // TODO: check if default method is expired / also what happens if the default payment doesn't work? */}
            {/* Show Upgrade */}
            {
              type === "upgrade" && <Upgrade
                proratedPrice={proratedPrice}
                proratedDays={proratedDays}
                plan={plan}
                manageSubscription={manageSubscription}
              />
            }
            {/* Show Downgrade */}
            {
              type === "downgrade" && <Downgrade
                proratedPrice={proratedPrice}
                proratedDays={proratedDays}
                plan={plan}
                profiles={profiles}
                currentProfile={currentProfile}
                setCurrentProfile={setCurrentProfile}
                setRefreshProfiles={setRefreshProfiles}
                manageSubscription={manageSubscription}
              />
            }
          </div>
        }
      </div>
    </div>
  </div>
}

export default SubscribeConfirm