
function PrivacyPolicy() {

}

function Page() {


  return <div>
    <PrivacyPolicy />
  </div>
}

export default Page