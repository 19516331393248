import '../styles/Payment.css'
import CommonManagePayment from './common/ManagePayment' 

function ManagePayment() {
  return <div className="App">
    <div className="AppBg"></div>
    <div id="PaymentBody">
      <div className="PaymentContent">
        <div style={{ margin: 27 }}>
          <h4>Payment Methods</h4>
          <CommonManagePayment />
        </div>
      </div>
    </div>
  </div>
}

export default ManagePayment