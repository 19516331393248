import '../styles/Navbar.css'
import { useState, useEffect, useRef } from 'react'
import { Link, useParams, useNavigate } from "react-router-dom"
import { useAuth } from "../context/AuthProvider"
import { track } from "../common/track"
import addIcon from "../assets/add.png"
import backIcon from '../assets/back.png'
import treasureIcon from '../assets/treasure.png'

function Navbar(props) {
  const { page, profiles, setCurrentProfile, currentProfile } = props
  const [ dropdownOpen, setDropdownOpen ] = useState()
  const { auth, logout, supabase, isSubscribed, user } = useAuth()
  const { stack_id } = useParams()
  const navigate = useNavigate()
  const ref = useRef(null)

  const atLanding = (page === "home" || page === "/auth/signup" || page === "/auth/forgot")
  const atWelcome = (page === "/p/onboarding" || page === "/p/profiles")
  const activeProfiles = profiles?.filter(p => p.active)
  const currProfile = profiles?.find(p => p.id === currentProfile)
  const maxProfiles = user?.user_metadata?.max_profiles
  const canAddNewProfile = profiles?.length < maxProfiles
  const hasBackPage = [
    "/p/subscribe/cancel",
    "/p/subscribe/confirm",
    "/p/subscribe/upgrade",
    "/p/subscribe/downgrade",
    "/p/payment/manage",
    "/p/payment/add",
    "/p/progress",
  ].includes(page) || page.match(/p\/stacks\/\S+/)

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setDropdownOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  // eslint-disable-next-line
  }, [])

  return (
    <nav className="Navbar">
      {
        // page.includes("/p/stack/") && <div 
        hasBackPage && 
        !page.includes("favorites") &&
        <div 
          className="BackBtn"
          onClick={ () => { 
            track({ supabase, profile: currentProfile, p: { event: "navbar_back" } })
            if (page === "/p/payment/manage") {
              navigate("/p/settings")
            }
            else {
              navigate(-1)
            }
          } }
        >
          <div className="BackBtnTab">
            <img src={backIcon} width={15} alt="" />
          </div>
          <div className="BackBtnTab">
            Back
          </div>
        </div>
      }
      <div className="Logo"><Link to={ auth ? "/p/stacks" : "/" }>STACKCOCO</Link></div>
      <ul className="NavbarMenu">
        {
          !auth && <>
            {
              atLanding && <li><Link to="/auth/login">Log In</Link></li>
            }
            {
              page === "/auth/login" && <li><Link to="/auth/signup">Sign Up</Link></li>
            }
          </>
        }
        {
          auth && <>
            {
              atWelcome && <li>
                <button 
                  className="Logout" 
                  onClick={ async () => { 
                    track({ supabase, profile: currentProfile, p: { event: "logout" } })
                    await logout()
                    navigate("/") 
                  } }
                >
                  Logout
                </button>
              </li>
            }
            {
              !atWelcome && 
              !atLanding && <>
                <li id="TreasureButton">
                  {/* INSANE HACK */}
                  <Link to={ stack_id ? `/p/stacks/${stack_id}/badges` : "/p/stacks/badges" }>
                    <div className="TreasureButton">
                      <div className="TreasureName">My Badges</div>
                      <div className="TreasureAvatar"><img src={treasureIcon} width={27} alt="" /></div>
                    </div>
                  </Link>
                </li>
                <li>
                  <div ref={ref} className="ProfileDropdown">
                    <div className="Profile" onClick={ 
                      () => { 
                        track({ supabase, profile: currentProfile, p: { event: "navbar_dropdown", open: !dropdownOpen } })
                        setDropdownOpen(!dropdownOpen) 
                      } 
                    }>
                      <div className="ProfileName">{currProfile?.name}</div>
                      <div className="ProfileAvatar"><img src={currProfile?.image_url} width={27} alt="" /></div>
                    </div>
                    {
                      dropdownOpen && <div className="ProfileDropdownContent">
                        <div className="ProfileDropdownTab AccountTab">
                          <h3>Account</h3>
                          <button className="Logout" onClick={ async () => { setDropdownOpen(false); navigate("/p/progress") } }>Progress</button>
                          <button className="Logout" onClick={ async () => { setDropdownOpen(false); navigate("/p/settings") } }>Settings</button>
                          <button className="Logout" onClick={ async () => { setDropdownOpen(false); } }>Support</button>
                          <button className="Logout" onClick={ async () => { await logout(); navigate("/") } }>Logout</button>
                        </div>
                        <div className="ProfileDropdownTab">
                          <h3>Profiles</h3>
                          {
                            activeProfiles.filter(p => p.id !== currentProfile).map((profile, key) => <div 
                              key={key}
                              className="Profile"
                              onClick={ () => { 
                                setCurrentProfile(profile.id);
                                setDropdownOpen(false);
                              }}
                            >
                              <div className="ProfileAvatar"><img src={profile.image_url} width={33} alt="" /></div>
                              <div className="ProfileName">{profile.name}</div>
                            </div>)
                          }
                          {
                            activeProfiles.length < process.env.REACT_APP_MAX_PROFILE && 
                            <div className="Profile">
                              <div className="ProfileAvatar"><img src={addIcon} width={30} alt="" /></div>
                              <div className="ProfileName" onClick={ async () => { 
                                setDropdownOpen(false)
                                if (
                                  isSubscribed &&
                                  canAddNewProfile
                                ) {
                                  navigate("/p/onboarding") 
                                }
                                else {
                                  navigate("/p/stacks/subscribe", { state: { action: "upgrade" } })
                                }
                              }} >Add New</div>
                            </div>
                          }
                          <div className="Profile">
                            <div className="ProfileName" onClick={ () => { setDropdownOpen(false); navigate("/p/profiles/edit") }} >Manage Profiles</div>
                          </div>
                          <div id="TreasureButtonMobile">
                            {/* INSANE HACK */}
                            <Link to={ stack_id ? `/p/stacks/${stack_id}/badges` : "/p/stacks/badges" }>
                              <div className="TreasureButton">
                                <div className="TreasureName">My Badges</div>
                                <div className="TreasureAvatar"><img src={treasureIcon} width={27} alt="" /></div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </li>
              </>
            }
          </>
        }
      </ul>
    </nav>
  );
}

export default Navbar
