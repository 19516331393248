import '../../styles/ManagePayment.css'
import { useState, useEffect  } from 'react'
import { useAuth } from "../../context/AuthProvider"
import { useNavigate } from "react-router-dom"
import axios from 'axios'

function ManagePayment(props) {
  const { source } = props
  const [ paymentMethods, setPaymentMethods ] = useState([])
  const [ refresh, setRefresh ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const { supabase } = useAuth()
  const navigate = useNavigate()

  async function listPaymentMethod() {
    try {
      const r = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/stripe/payment-methods`,
        { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
      )

      const data = r.data.data
      if (data) {
        const defaultData = data.find(d => d.default)
        const sortedData = defaultData ? [ defaultData, ...data.filter(d => !d.default) ] : []
        setPaymentMethods(sortedData)
      }
    }
    catch (e) {

    }
  }

  async function setDefault({ paymentMethodId }) {
    try {
      setLoading(true)
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/stripe/set-default`,
        {
          paymentMethodId
        },
        { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
      )
      setLoading(false)
    }
    catch (e) {
      console.log("error", e.message)
    }
  }

  async function deletePaymentMethod({ paymentMethodId }) {
    try {
      setLoading(true)
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/stripe/payment-methods/remove`,
        {
          paymentMethodId
        },
        { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
      )
      setLoading(false)
    }
    catch (e) {

    }
  }

  useEffect(() => {
    listPaymentMethod()
  // eslint-disable-next-line
  }, [ refresh ])

  return <div className="ManagePayment">
    
    <div className="PaymentList">
      {
        paymentMethods.map((method, key) => (
          <div
            className="PaymentItem"
            key={key}
          >
            <div className="Brand">{method.card.brand}</div>
            <div className="Number">Ending in {method.card.last4}</div>
            <div className="Exp">Exp. {method.card.exp_month}/{method.card.exp_year}</div>
            <div className="Actions">
              {
                !method.default && <div className="SetDefault">
                  <button 
                    onClick={ async () => { 
                      await setDefault({ paymentMethodId: method.id })
                      setRefresh(new Date().getTime())
                    } }
                  >
                    Set Primary
                  </button>
                </div>
              }
              {
                method.default && <div className="Default">
                  Primary
                </div>
              }
              {
                !method.default && <div className="Remove">
                  <button 
                    onClick={ async () => { 
                      await deletePaymentMethod({ paymentMethodId: method.id })
                      setRefresh(new Date().getTime())
                    } }
                    disabled={loading}
                  >
                    Remove
                  </button>
                </div>
              }
            </div>
          </div>
        ))
      }
    </div>

    <div className="AddPayment">
      <button 
        onClick={ () => { 
          navigate("/p/payment/add", { state: { source } })
        } }
      >
        Add New Card
      </button>
    </div>
  </div>
}

export default ManagePayment