import '../styles/Auth.css'
import axios from 'axios'
import { useState, useEffect } from 'react'
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom"
import { useAuth } from "../context/AuthProvider"
import { track } from "../common/track"

function UserAuth(props) {
  const { action, defaultEmail } = props;

  const [ email, setEmail ] = useState(defaultEmail);
  const [ password, setPassword ] = useState("");
  const [ emailError, setEmailError ] = useState("");
  const [ passwordError, setPasswordError ] = useState("");
  const [ passwordConfirmationError, setPasswordConfirmationError ] = useState("");
  const [ authError, setAuthError ] = useState(null);
  const [ confirm, setConfirm ] = useState(null);
  const [ passwordConfirmation, setPasswordConfirmation ] = useState("");
  
  const navigate = useNavigate();
  const location = useLocation();
  const { login, logout, updatePassword, resetPasswordForEmail, findProfiles } = useAuth();

  const isLogin = action === "login";
  const isSignup = action === "signup";
  const isForgot = action === "forgot";
  const isUpdatePassword = action === "update-password";
  const submitText = isLogin ? "LOG IN" : 
    isSignup ? "CREATE ACCOUNT" : 
    isForgot ? "RESET PASSWORD" : 
    isUpdatePassword ? "UPDATE PASSWORD" : null

  async function signup(email, password) {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/v1/users/signup`
    try {
      await axios.post(url, { email, password })
      track({ p: { event: "users_signup", email } })
      return { data: { user: { email } } }
    }
    catch (e) {
      track({ t: 'e', p: { event: "users_signup", email } })
      return { error: { message: e?.message ? e.message : "Something Went Wrong." } }
    }
  }

  useEffect(() => {
    track({ p: { event: "auth", action } })

    // eslint-disable-next-line
  }, [ action ])

  return submitText ? (
    <div className="Auth">
      {
        !confirm && <>
          {
            authError && <div class="TextInputError">{authError}</div>
          }

          <input type="text" placeholder="Email" value={email} onChange={ e => {
            if (emailError) { setEmailError("") }
            if (authError) { setAuthError(null) }
            setEmail(e.target.value) 
          }} />
          {
            emailError && <div class="TextInputError">{emailError}</div>
          }

          {
            !isForgot && <>
              <input type="password" placeholder="Password" value={password} onChange={ e => {
                if (passwordError) { setPasswordError("") }
                if (authError) { setAuthError(null) }
                setPassword(e.target.value)
              }} />
              {
                passwordError && <div class="TextInputError">{passwordError}</div>
              }
            </>
          }

          {
            isUpdatePassword && <>
              <input type="password" placeholder="Enter Password Again" value={passwordConfirmation} onChange={ e => {
                setPasswordConfirmation(e.target.value)
              }} />
              {
                passwordConfirmationError && <div class="TextInputError">{passwordConfirmationError}</div>
              }
            </>
          }

          <button onClick={ async e => {
            // eslint-disable-next-line
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
              setEmailError("Invalid Email Address") 
              return
            }

            if (!isForgot) {
              if (password.length < 8) {
                const passwordText = isLogin ? "Invalid Password" : "Password must be at least 8 characters"
                setPasswordError(passwordText)
                return
              }
            }
            
            // LOGIN
            if (isLogin) {
              const {
                data: { user, session },
                error
              } = await login(email, password);
              if (error) setAuthError(error.message);
              if (user && session) {
                const profileRes = await findProfiles();

                if (profileRes.error) {
                  setAuthError(profileRes.error.message);
                  await logout();
                }
                else {
                  if (profileRes.data.length === 0) {
                    navigate("/p/onboarding");
                  }
                  else if (profileRes.data.length === 1) {
                    navigate("/p/stacks");
                  }
                  else if (profileRes.data.length > 1) {
                    navigate("/p/profiles");
                  }
                }
              }
            }
            // SIGN UP
            else if (isSignup) {
              const { data, error } = await signup(email, password)
              if (error) setAuthError(error.message)
              if (data?.user && !error) setConfirm({ mode: "signup", params: data?.user })
            }
            // FORGOT PASSWORD
            else if (isForgot) {
              const { data, error } = await resetPasswordForEmail(email);
              if (error) setAuthError(error.message);
              setConfirm({ mode: "forgot", params: data?.user });
            }
            // UPDATE PASSWORD
            else if (isUpdatePassword) {
              if (password !== passwordConfirmation) {
                setPasswordConfirmationError("Passwords do not match")
              }
              else {
                const { error } = await updatePassword(password);
                if (error) {
                  setAuthError(error.message)
                }
                else {
                  navigate("/p/stacks", { replace: true })
                }
              }
            }

          }}>{submitText}</button>

          {
            isSignup && <div className="AcceptTerms">
              By clicking <b>CREATE ACCOUNT</b>, you agree to the STACKCOCO's terms of service and privacy policy.
            </div>
          }

          {
            isLogin && <div className="LoginFooter">
              <div>Forgot password? <Link to="/auth/forgot">Reset password</Link></div>
              <div>No account? <Link to="/auth/signup">Create one</Link></div>
            </div>
          }
        </>
      }
      {
        confirm && <>
          {
            confirm.mode === "signup" && <p>
              <br/>An email was sent to <u>{confirm.params.email}</u>. <br/>
              Please check your email and click on the confirmation link inside to verify your email.
            </p>
          }
          {
            confirm.mode === "forgot" && <p>
              If an account exists, an email will be sent to <u>{email}</u>. <br/><br/>
              Please check your email and click on the confirmation link in the email to reset your password.
            </p>
          }
        </>
      }
    </div>
  ) : (
    <Navigate to={"/"} replace state={{ path: location.pathname }} />
  );
}

export default UserAuth;
