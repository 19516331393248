import '../styles/Badges.css'
import axios from 'axios'
import { useAuth } from "../context/AuthProvider"
import { useState, useEffect } from 'react'
import { useOutletContext } from "react-router-dom"
import { track } from "../common/track"
import cancelIcon from '../assets/cancel.png'
import diamondIcon from '../assets/diamond.png'
import diamond63Icon from '../assets/diamond_63.png'
import loadingBarsIcon from '../assets/loadingBars.gif'

function Modal({ 
  showModal, 
  setShowModal, 
  currentBadge, 
  myDiamonds, 
  currentProfile, 
  supabase,
  setMyBadges,
  setMyDiamonds,
}) {
  if(!showModal) return null;

  return (
    <div className="ModalOverlay">
      <div className="ModalContent">
        <div className="ModalHeader">
          <h2>Confirm your purchase?</h2>
          <button
            className="CloseButton"
            onClick={() => setShowModal(false)}
          >
            <img src={cancelIcon} alt="close" />
          </button>
        </div>

        <div className="ModalBuy">
          <div className="ModalImage">
            <img src={`https://stackco.co/badges/${currentBadge.id}.jpg`} alt="" />
          </div>
          <div className="ModalBuyContent">
            <div className="ModalCost">
              <img src={diamondIcon} alt="" />
              <span>{currentBadge.cost}</span>
            </div>
            <div className="ModalBuyButton">
              <button 
                className={ currentBadge.cost > myDiamonds ? "DisabledBuyButton" : "" }
                disabled={currentBadge.cost > myDiamonds}
                onClick={ async () => {
                  const url = `${process.env.REACT_APP_API_ENDPOINT}/v1/treasures/buy`;
                  const r = await axios.post(
                    url, 
                    {
                      profile_id: currentProfile,
                      badge_id: currentBadge.id,
                    }, 
                    { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
                  );

                  // console.log("r", r)
                  if (r.data.exist) {
                    track({ supabase, profile: currentProfile, p: { event: "badge_exist_already", badge: currentBadge } })
                    alert("You own the badge already!")
                  }
                  else {
                    setMyDiamonds(r.data.diamonds)
                    setMyBadges(r.data.badges)
                    setShowModal(false)
                  }
                }}
              >
                { currentBadge.cost > myDiamonds ? "Not Enough Diamonds" : "Buy"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Badges() {
  // eslint-disable-next-line
  const [ profiles, currentProfile, setCurrentProfile ] = useOutletContext()
  const { supabase } = useAuth()
  const [ showModal, setShowModal ] = useState(false)
  const [ myBadges, setMyBadges ] = useState([])
  const [ myDiamonds, setMyDiamonds ] = useState(null)
  const [ badges, setBadges ] = useState([])
  const [ currentBadge, setCurrentBadge ] = useState()
  const [ fetchMore, setFetchMore ] = useState(false)
  const [ nextCursor, setNextCursor ] = useState(null)
  const [ loading, setLoading ] = useState(false)

  const openModal = () => {
    setShowModal(prev => !prev);
  };

  const findTreasures = async () => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/v1/treasures`;
    const r = await axios.post(
      url, 
      { 
        profile_id: currentProfile,
      }, 
      { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
    );
    
    if (r.data) {
      setMyDiamonds(r.data?.diamonds)
      setMyBadges(r.data?.badges)
    }
  }

  const findBadges = async () => {
    setFetchMore(false)
    setLoading(true)
    const url = `${process.env.REACT_APP_API_ENDPOINT}/v1/badges?sort_by=cost_asc&first=18${nextCursor ? `&after=${nextCursor}` : ""}`;
    const r = await axios.get(
      url,
      { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
    )

    if (r.data?.data?.length > 0) {
      setBadges([ ...badges, ...r.data.data ])
    }
    setNextCursor(r?.data?.next_cursor)
    setLoading(false)
  }

  useEffect(() => {
    findTreasures()
    findBadges()

    // eslint-disable-next-line
  }, [ currentProfile ]);

  useEffect(() => {
    if (nextCursor && fetchMore && !loading) {
      findBadges()
    }

    // eslint-disable-next-line
  }, [fetchMore, loading]);

  useEffect(() => {
    const stackBody = document.getElementById('BadgesBody');

    const handleScroll = async () => {
      const scrollTop = stackBody.scrollTop;
      const scrollHeight = stackBody.scrollHeight;
      const offsetHeight = stackBody.offsetHeight;
      const contentHeight = scrollHeight - offsetHeight;

      // Set FetchMore state to true when users scrolled almost to the bottom
      if (scrollTop > contentHeight * 0.8) {
        setFetchMore(true)
      }
    };

    stackBody.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  // eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      <div className="AppBg"></div>
      <Modal 
        showModal={showModal} 
        setShowModal={setShowModal} 
        currentBadge={currentBadge} 
        myDiamonds={myDiamonds}
        currentProfile={currentProfile}
        supabase={supabase}
        setMyBadges={setMyBadges}
        setMyDiamonds={setMyDiamonds}
      />
      <div id="BadgesBody">
        <div className="Badges">
          <div className="MyStuff">
            <h3>My Diamonds</h3>
            <div className="MyDiamonds">
              <img src={diamondIcon} width={99} height={99} alt="" />
              <div className="Amount">
                { myDiamonds == null ? <img src={loadingBarsIcon} width="99" height="99" alt="" /> : myDiamonds }
              </div>
            </div>
            <h3>My Badges</h3>
            <div className="MyBadges">
              {
                myBadges.length === 0 && <div className="">You currently have no badges.</div>
              }
              {
                myBadges.length > 0 && myBadges.map((badge, key) => (
                  <div 
                    key={key} 
                    className="BadgetItem"
                  >
                    <img src={`https://stackco.co/badges/${badge.id}.jpg`} alt="" />
                  </div>
                ))
              }
            </div>
          </div>
          <div className="BadgesStore">
            <h3>Badges Store</h3>
            <div className="BadgesCatalog">
              {
                badges.map((badge, key) => (
                  <div 
                    key={key} 
                    className="BadgetItem" 
                    onClick={ () => {
                      track({ supabase, profile: currentProfile, p: { event: "open_badge", badge } })
                      setCurrentBadge(badge)
                      openModal()
                    }}
                  >
                    <img src={`https://stackco.co/badges/${badge.id}.jpg`} alt="" />
                    <div className="BadgesBuy">
                      <img src={diamond63Icon} alt="" />
                      <div className="BadgeAmount">{badge.cost}</div>
                    </div>
                  </div>
                ))
              }
            </div>
            {
              nextCursor && <div
                className="BadgesFetchMore"
              >
                <button
                  onClick={ () => {
                    setFetchMore(true)
                  } }
                >Load More Badges</button>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Badges;