import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import './styles/index.css'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"

import App from './App'
import UserAuthPage from './components/UserAuthPage'
import AuthRoute from './components/AuthRoute'
import Page from './components/Page'
import Stacks from './components/Stacks'
import Stack from './components/Stack'
import Badges from './components/Badges'
import Subscribe from './components/Subscribe'
import SubscribeComplete from './components/SubscribeComplete'
import SubscribeCancel from './components/SubscribeCancel'
import SubscribeConfirm from './components/SubscribeConfirm'
import Payment from './components/Payment'
import Onboarding from './components/Onboarding'
import Profiles from './components/Profiles'
import Confirmation from './components/Confirmation'
import Settings from './components/Settings'
import AuthProvider from "./context/AuthProvider"
import { StripeProvider } from "./context/StripeProvider"
import ManagePayment from './components/ManagePayment'
import AddPayment from './components/AddPayment'
import Progress from './components/Progress'

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/p",
    element: <AuthRoute />,
    children: [
      {
        path: "stacks",
        element: <Stacks />,
        children: [
          {
            path: ":stack_id",
            element: <Stack />,
            children: [
              // HACK for now; this should belong to its own
              {
                path: "badges",
                element: <Badges />
              },
            ]
          },
          // HACK for now; this should belong to its own
          {
            path: "badges",
            element: <Badges />
          },
          {
            path: "subscribe",
            element: <Subscribe />
          },
          {
            path: "payment",
            element: <Payment />
          }
        ]
      },
      {
        path: "onboarding",
        element: <Onboarding />
      },
      {
        path: "profiles",
        element: <Profiles />
      },
      {
        path: "profiles/edit",
        element: <Profiles />
      },
      {
        path: "subscribe/complete",
        element: <SubscribeComplete />
      },
      {
        path: "subscribe/cancel",
        element: <SubscribeCancel />
      },
      {
        path: "subscribe/confirm",
        element: <SubscribeConfirm />
      },
      {
        path: "subscribe/upgrade",
        element: <Subscribe />
      },
      {
        path: "subscribe/downgrade",
        element: <Subscribe />
      },
      {
        path: "payment/manage",
        element: <ManagePayment />
      },
      {
        path: "payment/add",
        element: <AddPayment />
      },
      {
        path: "settings",
        element: <Settings />
      },
      {
        path: "progress",
        element: <Progress />
      }
    ]
  },
  {
    path: "/auth/confirm/:token_hash",
    element: <Confirmation />,
  },
  {
    path: "/auth/:action",
    element: <UserAuthPage />,
  },
  {
    path: "/page/:page",
    element: <Page />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <StripeProvider>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </StripeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
