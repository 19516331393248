import '../styles/ProfileForm.css'
import axios from 'axios'
import { useState } from 'react'
import { useNavigate, useOutletContext } from "react-router-dom"
import { useAuth } from "../context/AuthProvider"
import { track } from "../common/track"

function ProfileForm() {
  // eslint-disable-next-line
  const [ profiles, currentProfile, setCurrentProfile, setRefreshProfiles ] = useOutletContext()
  const [ step, setStep ] = useState(0)
  const [ name, setName ] = useState("")
  const [ birthday, setBirthday ] = useState("")
  // const [ interests, setInterests ] = useState([])
  // const [ loading, setLoading ] = useState()
  // const [ tags, setTags ] = useState([])
  const [ error, setError ] = useState(null)
  const { findProfiles, supabase, user } = useAuth()
  const navigate = useNavigate()
  const buttonText = step < 1 ? "NEXT" : "COMPLETE PROFILE"

  async function completeOnboarding() {
    const r = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/v1/feeds/onboard`,
      {
        name,
        birthday,
        interests: [],
      },
      { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
    )

    if (r?.data?.profile_id) {
      setCurrentProfile(r?.data?.profile_id)
    }
  }

  // async function getTags() {
  //   setLoading(true)
  //   const limit = 198
  //   let url = `${process.env.REACT_APP_API_ENDPOINT}/v1/tags?first=${limit}`
  //   const r = await axios.get(url, { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } })
  //   // TODO: display the tags in a more user-friendly way based on age
  //   setTags(r?.data?.data)
  //   setLoading(false)
  // }

  // useEffect(() => {
  //   track({ supabase, profile: currentProfile, p: { event: "onboarding", step } })

  //   if (step === 2) {
  //     getTags()
  //   }
  // // eslint-disable-next-line
  // }, [ step ])

  return <div className="ProfileForm">
    <div className="Progress">
      <div className={`Step ${ step === 0 ? "Active" : ""}`} onClick={ () => { setStep(0) }}></div>
      <div className={`Step ${ step === 1 ? "Active" : ""}`} onClick={ () => { if (step >= 1) { setStep(1) } }}></div>
      {/* <div className={`Step ${ step === 2 ? "Active" : ""}`}></div> */}
    </div>
    {
      step === 0 && <div className="Description">
        Let's create a profile for your child so we can provide content tailored to the age and needs.
      </div>
    }
    {
      step === 0 && <h3>What is your child's name?</h3>
    }
    {
      step === 1 && <h3>What is {name}'s birthday?</h3>
    }
    {
      // step === 2 && <h3>What topics is {name} looking to explore?</h3>
    }
    <div className="Inputs">
      {
        step === 0 && <input type="text" placeholder="Name" onChange={e => { setName(e.target.value); if(error) { setError(null) } } } />
      }
      {
        step === 1 && <input type="date" placeholder="Birthday" onChange={e => setBirthday(e.target.value)} />
      }
      {
        // step === 2 && <div className="Interests">
        //   {
        //     !loading && tags.map(tag => (
        //       <div class="chip">
        //         <input type="checkbox" id={tag.id} onClick={ e => { 
        //           const clickedId = e.currentTarget.getAttribute('id')
        //           if (interests.includes(clickedId)) {
        //             setInterests(interests.filter(i => i !== clickedId))
        //           }
        //           else {
        //             setInterests([ ...interests, clickedId ])
        //           }
        //         }}/>
        //         <label for={tag.id}>{tag.name}</label>
        //       </div>
        //     ))
        //   }
        //   {
        //     loading && <div>Loading...</div>
        //   }
        // </div>
      }
    </div> 
    {
      error && <div className="TextInputError">
        {error}
      </div>
    }
    <div className="Inputs">
      <button onClick={ async ()=> {  
        if (step === 0) {
          const profileRes = await findProfiles()

          // HACK: Only ONE profile allowed for FREE accounts
          if (!user?.user_metadata?.subscribed) {
            if (profileRes?.data?.length > 0) {
              navigate("/p/stacks")
            }
          }

          if (profileRes.data.find(d => d.name === name)) {
            track({ supabase, profile: currentProfile, t: 'e', p: { event: "profile_exists", name } })

            // Profile name already exists
            setError("Profile already exists")
          }
          else {
            setStep(step + 1)
          }
        }
        // else if (step === 1) {
        //   setStep(step + 1)
        // }
        else {
          try { 
            await completeOnboarding()
          }
          catch (e) { track({ supabase, profile: currentProfile, t: 'e', p: { event: "create_profile", name, birthday } }) }
          navigate("/p/stacks", { state: { refreshProfiles: true } });
        }
      } }>{buttonText}</button>
    </div>
  </div>
}

export default ProfileForm;
