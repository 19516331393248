export function oneYearAgo(timestamp) {
  let date = new Date(timestamp)
  date.setFullYear(date.getFullYear() - 1)
  
  return date
}

export function formatDate(inputDateStr) {
  const date = new Date(inputDateStr);
  
  // Months array to get month names
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const month = months[date.getMonth()];
  const day = date.getDate();  // This will give day of the month
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}