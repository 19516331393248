import '../styles/Stack.css'
import { useAuth } from "../context/AuthProvider"
import speakIcon from '../assets/speak.png'
import speakDisabledIcon from '../assets/speakDisabled.png'
import cancelIcon from '../assets/cancel.png'
import { useState, useEffect, useRef, Fragment } from 'react'
import { useParams, useLocation, useOutletContext, useNavigate, Outlet } from "react-router-dom"
import { track } from "../common/track"
import axios from 'axios'
import YouTube from 'react-youtube'
import bunnyAvatar from '../assets/coco.jpg'
import pizzaAvatar from '../assets/pizza.png'
import weightIcon from '../assets/weight.png'
import readingIcon from '../assets/reading.png'
import checkIcon from '../assets/check.png'
import goldStarIcon from '../assets/goldStar.jpg'
import crossIcon from '../assets/cross.png'
import diamond63Icon from '../assets/diamond_63.png'
import loadingBarsIcon from '../assets/loadingBars.gif'
import lockIcon from '../assets/lock.png'
import moreIcon from '../assets/more.png'

const TERM_LIMIT = 18

function HighlightableSpan(props) {
  const { fetchAndPlayAudio, giantFont, text } = props;
  const [highlight, setHighlight] = useState(false);

  const handleClick = async () => {
    setHighlight(true);
    await fetchAndPlayAudio({ dictionary: true, text });
    setTimeout(() => setHighlight(false), 1000);
  };

  useEffect(() => {
    return () => clearTimeout(handleClick);
  // eslint-disable-next-line
  }, []);

  return (
    <span 
      className={`${ highlight ? 'Highlight' : '' } ${ giantFont ? 'Giant' : '' }`}
      onClick={handleClick}
    >
      {props.children}
    </span>
  );
}

function DisplayTerm(props) {
  if (props.text === null || props.text === undefined) {
    return <div className="fraction"></div>;
  }

  const parseFractionText = (text) => {
    const fractionMatch = text?.match(/(\d+\/\d+)(.*)/);
    if (fractionMatch) {
      const substrings = fractionMatch[1].split("/");
      const numbers = substrings.map((s) => {
        const result = parseFloat(s);
        if (isNaN(result)) {
          return text;
        }
        return result;
      });
      return {
        numerator: numbers[0],
        denominator: numbers[1],
        trailingText: fractionMatch[2]
      };
    }
    return text;
  };

  // at least 2 characters is a number to activate fraction display
  // FIXME: this is still a hack because some none fraction terms may still have more than 2 numbers
  let fractions
  if (typeof props.text === "string" && props.text?.match(/\d/g)?.length >= 2) {
    const fractionsText = props.text.split(/(\+|-|x|÷)/).filter(s => s);
    fractions = fractionsText.map(ft => parseFractionText(ft.trim()));
  }
  else {
    fractions = [ props.text ]
  }

  return (
    <div className="fraction">
      {fractions.map((fraction, index) => {
        if (typeof fraction === 'string') {
          return fraction === "" ? "" : <span className="word" key={index}>{fraction.trim()}</span>;
        } 
        else if (typeof fraction === 'number') {
          return <span className="word" key={index}>{fraction}</span>;
        }
        else {
          return (
            <Fragment key={index}>
              <div className="fraction-block">
                <span className="fraction--numerator">{fraction.numerator}</span>
                <span className="fraction--denominator">{fraction.denominator}</span>
              </div>
              {fraction.trailingText && <span>{fraction.trailingText}</span>}
            </Fragment>
          );
        }
      })}
    </div>
  );
}

function Challenge(props) {
  const { 
    supabase,
    stack_id,
    term_id,
    termdata,
    currentProfile,
    fetchAndPlayAudio, 
    reading, 
    setReading, 
    stopAudio
  } = props
  
  const [ selectedAnswers, setSelectedAnswers ] = useState({});
  const [ correctAnswers, setCorrectAnswers ] = useState({});
  const [ wrongAnswers, setWrongAnswers ] = useState({});
  const [ gainedDiamonds, setGainedDiamonds ] = useState({});

  return <>
    <div class="dialog-bubble">
      {
        termdata && termdata.map((paragraph, k) => {
          const questionWords = paragraph?.question ? paragraph.question.split(" ") : []
          const giantFont = false
          const choices = JSON.parse(paragraph?.choices)

          return <div key={k}>
            {/* QUESTION */}
            {
              paragraph.question && <div class="bubble bubble-right">
                <div class="avatar">
                  <img src={bunnyAvatar} alt="" />
                </div>
                <p>
                  {
                    !reading && <span 
                      class="Speaker"
                      onClick={ () => { 
                        setReading(true);
                        fetchAndPlayAudio({ 
                          text: paragraph.question,
                          termdata_id: paragraph.id,
                          type: "questions"
                        });
                      }}
                    >
                      <img src={speakIcon} width={27} alt="" />
                    </span>
                  }
                  {
                    reading && <span 
                      class="Speaker"
                      onClick={ () => { 
                        setReading(false);
                        stopAudio();
                      }}
                    >
                      <img src={cancelIcon} width={27} alt="" />
                    </span>
                  }
                  {
                    questionWords.map((word, key) => <HighlightableSpan 
                      key={key}
                      fetchAndPlayAudio={fetchAndPlayAudio}
                      giantFont={giantFont}
                      text={word}
                    >
                      {<DisplayTerm text={word} />}
                    </HighlightableSpan>)
                  }
                </p>
              </div>
            }
            <div class="bubble bubble-left">
              {/* AVATAR and Action Icon */}
              <div class="avatar">
                <img src={pizzaAvatar} alt="" />
              </div>
              {/* WORKOUT */}
              <p>
                {
                  !correctAnswers?.[paragraph.id] && <div class="Choices">
                    {
                      choices?.map((choice, key) => <div
                        key={key}
                        class={ key === selectedAnswers?.[paragraph.id] ? "Selected" : "Choice" }
                        onClick={ () => {
                          setWrongAnswers({
                            ...wrongAnswers,
                            [paragraph.id]: undefined,
                          })

                          setSelectedAnswers(selectedAnswers => ({
                            ...selectedAnswers,
                            [paragraph.id]: key,
                          }))
                        } }
                      >
                        {
                          wrongAnswers?.[paragraph.id] === key && <span>
                            <img src={crossIcon} width="30" height="30" alt="" />
                            <div style={{ color: "red" }}>Incorrect 😥 Try Again 😁</div>
                          </span>
                        }
                        <span>
                          { 
                            (key === selectedAnswers?.[paragraph.id] && (wrongAnswers?.[paragraph.id] !== key))
                            ? <img src={checkIcon} width="27" height="27" alt="" /> : ""} {<DisplayTerm text={choice.text} />
                          }
                        </span>
                      </div>
                      )
                    }
                    <button 
                      class="Submit"
                      disabled={selectedAnswers?.[paragraph.id] == null}
                      onClick={ async () => {
                        if (choices[selectedAnswers?.[paragraph.id]]?.correct) {
                          // Correct answer
                          setCorrectAnswers({
                            ...correctAnswers,
                            [paragraph.id]: true,
                          })
                        }
                        else {
                          // Wrong answer
                          setWrongAnswers({
                            ...wrongAnswers,
                            [paragraph.id]: selectedAnswers?.[paragraph.id],
                          })
                        }

                        let data
                        const url = `${process.env.REACT_APP_API_ENDPOINT}/v1/attempts`
                        const choice_index = selectedAnswers?.[paragraph.id]

                        try {
                          const r = await axios.post(
                            url, 
                            {
                              profile_id: currentProfile,
                              termdata_id: paragraph.id,
                              choice_index,
                              is_challenge: 1,
                            },
                            { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
                          )
                          data = r.data
                          track({ 
                            supabase, 
                            profile: currentProfile, 
                            p: { 
                              event: "challenge_submit_answer", 
                              termdata_id: paragraph?.id, 
                              choice_index,
                              stack_id,
                              term_id,
                            } 
                          })
                        }
                        catch (e) {
                          track({ 
                            supabase, 
                            profile: currentProfile, 
                            t: 'e', 
                            p: { 
                              event: "challenge_submit_answer", 
                              termdata_id: paragraph?.id, 
                              choice_index, 
                              stack_id,
                              term_id,
                              e: e?.message 
                            } 
                          })
                        }

                        if (choices[selectedAnswers?.[paragraph.id]]?.correct) {
                          setGainedDiamonds({
                            ...gainedDiamonds,
                            [paragraph.id]: data?.diamonds
                          })
                        }

                      } }
                    >
                      SUBMIT
                    </button>
                  </div>
                }
                {
                  correctAnswers?.[paragraph.id] && <div class="Congrats">
                    <img src={goldStarIcon} alt="" />
                    <p>
                      <h3>Congratulations!</h3>
                      <span>You got the correct answer 😁</span>
                      <div className="Earnings">
                        <div className="EarningsNotice">You just earned {gainedDiamonds[paragraph.id] == null ? <div className="EarningsNoticeImg"><img src={loadingBarsIcon} alt="" /></div> : <b>&nbsp;{gainedDiamonds[paragraph.id]}</b>}
                        </div>
                        <img src={diamond63Icon} alt=""/>
                      </div>
                    </p>
                  </div>
                }
              </p>
            </div>
          </div>
        })
      }
    </div>
  </>
}

function Stack() {
  // eslint-disable-next-line
  const [ profiles, currentProfile, setCurrentProfile ] = useOutletContext()
  const { supabase, isSubscribed } = useAuth()
  const navigate = useNavigate()
  const { stack_id } = useParams()
  const audioRef = useRef()
  const location = useLocation()
  const [ termNames, setTermNames ] = useState([])
  const [ paidTermNames, setPaidTermNames ] = useState([])
  const [ paidTermTotal, setPaidTermTotal ] = useState([])
  const [ nextCursor, setNextCursor ] = useState(null)
  const [ fetchMore, setFetchMore ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ descriptionLoading, setDescriptionLoading ] = useState(true)
  const [ currentTerm, setCurrentTerm ] = useState(null)
  const [ currentLangTerm, setCurrentLangTerm ] = useState(null)
  const [ reading, setReading ] = useState(false)
  const [ audioLoading, setAudioLoading ] = useState(false)
  const [ currentImages, setCurrentItems ] = useState([])
  // const [ lang, setLang ] = useState('en-US');
  const [ currentTermdata, setCurrentTermdata ] = useState(null)
  const [ workout, setWorkout ] = useState({})
  const [ selectedAnswers, setSelectedAnswers ] = useState({})
  const [ correctAnswers, setCorrectAnswers ] = useState({})
  const [ wrongAnswers, setWrongAnswers ] = useState({})
  const [ gainedDiamonds, setGainedDiamonds ] = useState({})
  const [ isChallenge, setIsChallenge ] = useState(false)
  const [ challengeTermdata, setChallengeTermdata ] = useState([])

  const endOfTermNames = termNames.length > 0 && !nextCursor
  // const stackName = location?.state?.name
  const hasChallenge = currentTermdata?.[0]?.question
  const sortBy = "alpha_asc"
  const r2Dir = process.env.NODE_ENV === "development" ? "stage" : "prod"

  async function fetchAndPlayAudio({ text, dictionary = false, term_id, termdata_id, type }) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_TTS_API_ENDPOINT}/tts`, 
        { 
          text,
          dictionary,
          term_id,
          termdata_id,
          type,
        }, 
        { 
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${supabase.realtime.accessToken}`,
          }
        }
      )
      // console.log("response", response)
      if (response.data) {
        const buffer = await response.data.arrayBuffer()
        const audioUrl = URL.createObjectURL(new Blob([buffer], { type: 'audio/mpeg' }))
        audioRef.current.src = audioUrl
        audioRef.current.play()
      }

      track({ supabase, profile: currentProfile, p: { event: "tts", text, dictionary, term_id,termdata_id, type } })
    } catch (error) {
      // console.error('Failed to fetch and play audio:', error);
      track({ supabase, profile: currentProfile, t: 'e', p: { event: "tts", text, dictionary, term_id,termdata_id, type, e: error?.message } })
    }
  };

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause() // Pause the audio
      audioRef.current.currentTime = 0 // Reset playback position to the start
      track({ supabase, profile: currentProfile, p: { event: "tts_stop" } })
    }
  }

  async function getTermdata({ term }) {
    let data = []

    try {
      const url = `${process.env.REACT_APP_API_ENDPOINT}/v1/termdata?term_id=${term?.id}&first=18`
      const r = await axios.get(url, { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } })
      data = r?.data?.data
      track({ supabase, profile: currentProfile, p: { event: "find_termdata", term_id: term?.id, stack_id } })
    }
    catch (e) {
      track({ supabase, profile: currentProfile, t: 'e', p: { event: "find_termdata", term_id: term?.id, stack_id, e: e?.message } })
    }

    setCurrentTermdata(data)
    setDescriptionLoading(false)
  }

  async function getChallenge({ stack_id }) {
    let data = []

    try {
      const url = `${process.env.REACT_APP_API_ENDPOINT}/v1/termdata/super-workout?stack_id=${stack_id}&profile_id=${currentProfile}`
      const r = await axios.get(url, { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } })
      data = r.data.data
      track({ supabase, profile: currentProfile, p: { event: "find_termdata_super-workout", stack_id } })
    }
    catch (e) {
      track({ supabase, profile: currentProfile, t: 'e', p: { event: "find_termdata_super-workout", stack_id, e: e?.message } })
    }

    if (data?.length > 0) {
      setChallengeTermdata(data)
    }
    else {
      navigate('/p/stacks/subscribe')
    }
  }

  async function getImages({ term }) {
    let data = []

    try {
      const url = `${process.env.REACT_APP_API_ENDPOINT}/v1/images?term_id=${term?.id}&sort_by=position`
      const r = await axios.get(url, { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } })
      data = r?.data?.data
      track({ supabase, profile: currentProfile, p: { event: "find_images", term_id: term?.id, stack_id } })
    }
    catch (e) {
      track({ supabase, profile: currentProfile, t: 'e', p: { event: "find_images", term_id: term?.id, stack_id, e: e?.message } })
    }

    setCurrentItems(data)
  }

  async function fetchTermNames(reset) {
    setFetchMore(false)
    setLoading(true)
    const limit = nextCursor ? process.env.REACT_APP_MAX_PROFILE : TERM_LIMIT

    let url = `${process.env.REACT_APP_API_ENDPOINT}/v1/terms?stack_id=${stack_id}&sort_by=${sortBy}`

    const isBefore = sortBy === "alpha_desc"

    if (isBefore) { url += `&last=${limit}` }
    else { url += `&first=${limit}` }

    if (!reset && nextCursor) {
      const direction = isBefore ? "before" : "after"
      url += `&${direction}=${nextCursor}`
    }

    let data = [], next_cursor

    try {
      const r = await axios.get(url, { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } })
      // console.log("fetch term names r", r);
      data = r?.data?.data
      next_cursor = r?.data?.next_cursor

      // do not track the default term
      if (!currentTerm) {
        track({ supabase, profile: currentProfile, p: { event: "find_termnames", stack_id, after: nextCursor } })
      }
    }
    catch (e) {
      track({ supabase, profile: currentProfile, t: 'e', p: { event: "find_termnames", stack_id, after: nextCursor, e: e?.message } })
    }

    if (reset) { setTermNames(data) }
    else { setTermNames(prevTermNames => [ ...prevTermNames, ...data ]) }
    setNextCursor(next_cursor)
    setLoading(false)
    
    if (!currentTerm) {
      setCurrentTerm(data?.[0])
    }
  }

  async function fetchPaidTermNames() {
    let url = `${process.env.REACT_APP_API_ENDPOINT}/v1/terms/paid?stack_id=${stack_id}&$limit=6`

    let data = [], total = 0
    try {
      const r = await axios.get(url, { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } })
      data = r?.data?.data
      total = r?.data?.total
      track({ supabase, profile: currentProfile, p: { event: "find_paidtermnames", stack_id } })
    }
    catch (e) {
      track({ supabase, profile: currentProfile, t: 'e', p: { event: "find_paidtermnames", stack_id } })
    }

    const sortedData = data.sort(function(a, b) {
      var nameA = a.name.toUpperCase()
      var nameB = b.name.toUpperCase()
      if (nameA < nameB) {
          return -1
      }
      if (nameA > nameB) {
          return 1
      }
  
      // Names must be equal
      return 0
    })

    setPaidTermTotal(total)
    setPaidTermNames(sortedData)
  }

  function handleEnd() {
    setReading(false)
  }

  // Scroll listener for term names
  useEffect(() => {
    const termList = document.getElementById('TermList').childNodes[0]

    const handleScroll = async () => {
      const scrollTop = termList.scrollTop;
      const scrollHeight = termList.scrollHeight;
      const offsetHeight = termList.offsetHeight;
      const contentHeight = scrollHeight - offsetHeight;

      // Set FetchMore state to true when users scrolled almost to the bottom
      if (scrollTop > contentHeight * 0.72) {
        setFetchMore(true);
      }
    }

    termList.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  // eslint-disable-next-line
  }, [])

  // Endless scroll for term names
  useEffect(() => {
    if (fetchMore && !endOfTermNames && !loading) {
      fetchTermNames()
    }
  // eslint-disable-next-line
  }, [ fetchMore, endOfTermNames, loading ])

  // Fetch first set of term names (free)
  useEffect(() => {
    fetchTermNames(true)
  // eslint-disable-next-line
  }, [])

  // Fetch paid term names
  useEffect(() => {
    if (isSubscribed) {
      setPaidTermTotal(0)
      setPaidTermNames([])
    }
    else {
      fetchPaidTermNames()
    }
  // eslint-disable-next-line
  }, [ isSubscribed ])

  useEffect(() => {
    setCurrentLangTerm(null)
    // setLang('en-US');
    if (currentTerm) {
      getTermdata({ term: currentTerm })
      getImages({ term: currentTerm })
    }
    // eslint-disable-next-line
  }, [ currentTerm ])

  useEffect(() => {
    if (isChallenge) {
      getChallenge({ stack_id })
    }
    // eslint-disable-next-line
  }, [ isChallenge ])

  // Google TTS audio
  useEffect(() => {
    const audioElement = audioRef.current

    if (audioElement) {
      audioElement.addEventListener('ended', handleEnd)
    }

    // Clean up the event listener when the component is unmounted
    return () => {
      if (audioElement) {
        audioElement.removeEventListener('ended', handleEnd)
      }
    }
    // eslint-disable-next-line
  }, [])

  // TODO: 6/22/23 - currently not supported yet; 
  // need to add "back" in NavBar to exit video mode
  // need a button nearby the video to enter full screen
  // const [ isFullScreen, setIsFullScreen ] = useState(false);
  // const [ currentVideoId, setCurrentVideoId ] = useState();
  const isFullScreen = false
  const currentVideoId = false

  // console.log("currentImages", currentImages)

  return (<>
    {/* 
      This is to ensure Stack doesn't reset the term infinite scroll 
      - /p/stacks/:stack_id
    */}
    {
      location.pathname.match(/p\/stacks\/\S+\/badges/) && <div className="StackModal">
        <Outlet context={[ profiles, currentProfile, setCurrentProfile ]} />
      </div>
    }
    <div className="App">
      {
        isFullScreen && 
        currentVideoId && <div
          style={{
            position: 'absolute',
            zIndex: 999,
            marginTop: 45,
            height: '100vh',
            width: '100vw',
          }}
        >
          <YouTube 
            videoId={currentVideoId} 
            opts={{
              // TODO: this doesn't handle the case if the window resizes yet 
              height: window.innerHeight - 45,
              width: '100%',
              playerVars: {
                modestbranding: 1,
                fs: 0,
              }
            }} 
          />
        </div>
      }

      <audio ref={audioRef} controls style={{ display: 'none' }} />
      <div className="AppBg"></div>
      <div className="StackShow">
        {/* TERM NAME LIST */}
        <div id="TermList">
          <ul>
            {
              hasChallenge && <li>
                <button 
                  className="SuperWorkout" 
                  onClick={ () => {
                    // if (isSubscribed) {
                    //   setIsChallenge(true)
                    // }
                    // else {
                    //   navigate(`/p/stacks/subscribe`)
                    // }

                    setIsChallenge(true)
                  }}
                >
                  CHALLENGE
                </button>
              </li>
            }
            {
              termNames?.map((term, key) => <li 
                key={key}
                onClick={ () => { 
                  setIsChallenge(false);
                  setCurrentTerm(term);
                  const scrollElement = document.querySelector('#ScrollImages');
                  if (scrollElement) {
                    scrollElement.scrollLeft = 0
                  }
                }}
                className={ currentTerm?.id === term?.id && !isChallenge ? "Bold" : "" }
              >
                <span className={ term.name.length > 18 ? 'Small' : '' } >{<DisplayTerm text={term.name} />}</span>
              </li>
              )
            }
            {
              paidTermTotal > 0 &&
              !isSubscribed &&
              paidTermNames?.map((term, key) => <li
                key={key}
                onClick={ () => {
                  navigate(`/p/stacks/subscribe`)
                }}
              >
                <span className={ term.name.length > 18 ? 'Small Lock' : 'Lock' } >
                  {<DisplayTerm text={term.name} />}
                </span>
                <img src={lockIcon} alt="" width={16} height={16} />
              </li>
              )
            }
            {
              paidTermTotal > 0 &&
              !isSubscribed &&
              <div 
                style={{ 
                  marginLeft: 6, 
                  cursor: 'pointer',
                }}
                onClick={ () => {
                  navigate(`/p/stacks/subscribe`)
                } }
              >
                <img src={moreIcon} alt="" width={32} height={32} /> 
              </div>
            }
            {
              paidTermTotal > 0 &&
              !isSubscribed &&
              <div 
                className="SubscribeMoreTerms"
                onClick={ () => {
                  navigate(`/p/stacks/subscribe`)
                } }
              >
                Subscribe for <br/>
                <b>{paidTermTotal}</b> more terms!
              </div>
            }
            {
              termNames?.length >= TERM_LIMIT &&
              !endOfTermNames && 
              <li>
                <button
                  className="LoadMore"
                  onClick={ () => {
                    setFetchMore(true);
                  } }
                >
                  Load More
                </button>
              </li>
            }
          </ul>
        </div>
        <div className="Term">
          <div className="TermHeader">
            <div className="TermWord">
              <span className={ currentTerm?.name?.length >= 18 ? 'Small' : '' }>
                { isChallenge && hasChallenge ? "Challenge 💪" : <DisplayTerm text={currentTerm?.name} /> }
              </span>
              {
                !isChallenge && 
                !audioLoading && 
                <span onClick={ async () => { 
                  setAudioLoading(true);
                  await fetchAndPlayAudio({ 
                    text: currentLangTerm ? currentLangTerm : currentTerm.name,
                    term_id: currentTerm.id,
                  });

                  // temporarily disable the speak so people won't double click it
                  await new Promise(resolve => setTimeout(resolve, 1800));
                  setAudioLoading(false);

                  // use on-device TTS
                  // speak(currentTerm.name); 
                }}>
                  <img src={speakIcon} width={45} alt="" />
                </span>
              }
              {
                audioLoading && <span>
                  <img src={speakDisabledIcon} width={45} alt="" />
                </span>
              }
            </div>
          </div>
          <div className="TermDetail">
            {
              !isChallenge && <>
                {/* IMAGES */}
                {
                  currentImages?.length > 0 && <div className="TermImages" id="ScrollImages">
                    {
                      currentImages.map((currentImage, key) => (
                        <div key={key} className="TermImage">
                          {
                            !currentImage?.youtube_video_id && <img 
                              src={`https://stackco.co/${r2Dir}/terms/${currentImage?.id}.jpg`} 
                              alt="" 
                            />
                          }
                          {
                            currentImage?.youtube_video_id && <YouTube 
                              videoId={currentImage.youtube_video_id} 
                              opts={{
                                height: '396',
                                width: '693',
                                playerVars: {
                                  modestbranding: 1,
                                  fs: 0,
                                  rel: 0,
                                }
                              }} 
                            />
                          }
                        </div>
                      ))
                    }
                  </div>
                }
                {/* READING & WORKOUT */}
                {
                  !descriptionLoading &&
                  // currentDescription && 
                  currentTermdata &&
                  <div className="TermDescription">
                    
                    <div class="dialog-bubble">
                      {
                        currentTermdata.map((paragraph) => {
                          // console.log("paragraph", paragraph)
                          const words = paragraph.content.split(" ")
                          const questionWords = paragraph?.question ? paragraph.question.split(" ") : []
                          const giantFont = words?.length < 9
                          const choices = JSON.parse(paragraph?.choices)

                          return <div>
                            {/* QUESTION */}
                            {
                              paragraph.question && <div class="bubble bubble-right">
                                <div class="avatar">
                                  <img src={pizzaAvatar} alt="" />
                                </div>
                                <p>
                                  {
                                    !reading && <span 
                                      class="Speaker"
                                      onClick={ () => { 
                                        setReading(true);
                                        // speakData.rate = 1;
                                        // speak(currentDescription);
                                        fetchAndPlayAudio({ 
                                          text: paragraph.question,
                                          type: "questions",
                                          termdata_id: paragraph.id,
                                        });
                                      }}
                                    >
                                      <img src={speakIcon} width={27} alt="" />
                                    </span>
                                  }
                                  {
                                    reading && <span 
                                      class="Speaker"
                                      onClick={ () => { 
                                        setReading(false);
                                        // window.speechSynthesis.cancel();
                                        stopAudio();
                                      }}
                                    >
                                      <img src={cancelIcon} width={27} alt="" />
                                    </span>
                                  }
                                  {
                                    questionWords.map((word, key) => <HighlightableSpan 
                                      key={key}
                                      fetchAndPlayAudio={fetchAndPlayAudio}
                                      giantFont={giantFont}
                                      text={word}
                                    >
                                      {<DisplayTerm text={word} />}
                                    </HighlightableSpan>)
                                  }
                                </p>
                              </div>
                            }

                            <div class="bubble bubble-left">
                              {/* AVATAR and Action Icon */}
                              <div class="avatar">
                                <img src={bunnyAvatar} alt="" />
                                {
                                  paragraph.question && <>
                                    {
                                      !workout?.[paragraph.id] && <span 
                                        class="Workout"
                                        onClick={ () => {
                                          track({ 
                                            supabase, 
                                            profile: currentProfile, 
                                            p: { 
                                              event: "work_out", 
                                              paragraph_id: paragraph.id,
                                              stack_id,
                                              term_id: currentTerm?.id,
                                            } 
                                          })

                                          setWorkout({
                                            ...workout,
                                            [paragraph.id]: true, 
                                          })
                                        } }
                                      >
                                        <img src={weightIcon} alt="" />
                                        <span>Work Out</span>
                                      </span>
                                    }
                                    {
                                      workout?.[paragraph.id] && <span 
                                        class="Workout"
                                        onClick={ () => {
                                          track({ supabase, profile: currentProfile, p: { event: "read", paragraph_id: paragraph.id } })

                                          setWorkout({
                                            ...workout,
                                            [paragraph.id]: false, 
                                          })

                                          setCorrectAnswers({
                                            ...correctAnswers,
                                            [paragraph.id]: undefined,
                                          })

                                          setWrongAnswers({
                                            ...wrongAnswers,
                                            [paragraph.id]: undefined,
                                          })

                                          setSelectedAnswers({
                                            ...selectedAnswers,
                                            [paragraph.id]: null,
                                          })
                                        } }
                                      >
                                        <img src={readingIcon} alt="" />
                                        <span>Read</span>
                                      </span>
                                    }
                                  </>
                                }
                              </div>
                              {/* READING */}
                              {
                                !workout?.[paragraph.id] && <p>
                                  {
                                    !reading && <span
                                    class="Speaker"
                                    onClick={ () => { 
                                      setReading(true);
                                      // speakData.rate = 1;
                                      // speak(currentDescription);
                                      fetchAndPlayAudio({ 
                                        text: paragraph.content,
                                        termdata_id: paragraph.id,
                                        type: "content",
                                      });
                                    }}>
                                      <img src={speakIcon} width={27} alt="" />
                                    </span>
                                  }
                                  {
                                    reading && <span
                                      class="Speaker"
                                      onClick={ () => { 
                                      setReading(false);
                                      // window.speechSynthesis.cancel();
                                      stopAudio();
                                    }}>
                                      <img src={cancelIcon} width={27} alt="" />
                                    </span>
                                  }
                                  {
                                    words.map((word, key) => <HighlightableSpan 
                                      key={key}
                                      fetchAndPlayAudio={fetchAndPlayAudio}
                                      giantFont={giantFont}
                                      text={word}
                                    >
                                      {<DisplayTerm text={word} />}
                                    </HighlightableSpan>)
                                  }
                                </p>
                              }
                              {/* WORKOUT */}
                              {
                                workout?.[paragraph.id] && <p>
                                  {
                                    !correctAnswers?.[paragraph.id] && <div class="Choices">
                                      {
                                        choices?.map((choice, key) => <div
                                          key={key}
                                          class={ key === selectedAnswers?.[paragraph.id] ? "Selected" : "Choice" }
                                          onClick={ () => {
                                            track({ 
                                              supabase, 
                                              profile: currentProfile, 
                                              p: { 
                                                event: "select_answer", 
                                                choice, 
                                                paragraph_id: paragraph?.id,
                                                stack_id,
                                                term_id: currentTerm?.id,
                                              } 
                                            })

                                            setWrongAnswers({
                                              ...wrongAnswers,
                                              [paragraph.id]: undefined,
                                            })

                                            setSelectedAnswers(selectedAnswers => ({
                                              ...selectedAnswers,
                                              [paragraph.id]: key,
                                            }))
                                          } }
                                        >
                                          {
                                            wrongAnswers?.[paragraph.id] === key && <span>
                                              <img src={crossIcon} width="30" height="30" alt="" />
                                              <div style={{ color: "red" }}>Incorrect 😥 Try Again 😁</div>
                                            </span>
                                          }
                                          <span>
                                            { 
                                              (key === selectedAnswers?.[paragraph.id] && (wrongAnswers?.[paragraph.id] !== key))
                                              ? <img src={checkIcon} width="27" height="27" alt="" /> : ""} {<DisplayTerm text={choice.text} />}
                                          </span>
                                        </div>
                                        )
                                      }
                                      <button 
                                        class="Submit"
                                        disabled={selectedAnswers?.[paragraph.id] == null}
                                        onClick={ async () => {
                                          if (choices[selectedAnswers?.[paragraph.id]]?.correct) {
                                            // Correct answer
                                            setCorrectAnswers({
                                              ...correctAnswers,
                                              [paragraph.id]: true,
                                            })
                                          }
                                          else {
                                            // Wrong answer
                                            setWrongAnswers({
                                              ...wrongAnswers,
                                              [paragraph.id]: selectedAnswers?.[paragraph.id],
                                            })
                                          }

                                          let data
                                          const url = `${process.env.REACT_APP_API_ENDPOINT}/v1/attempts`
                                          const choice_index = selectedAnswers?.[paragraph.id]
                                          try {
                                            const r = await axios.post(
                                              url, 
                                              {
                                                profile_id: currentProfile,
                                                termdata_id: paragraph.id,
                                                choice_index,
                                                is_challenge: 0,
                                              },
                                              { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } }
                                            )
                                            data = r.data
                                            track({ 
                                              supabase, 
                                              profile: currentProfile, 
                                              p: { 
                                                event: "submit_answer", 
                                                paragraph_id: paragraph?.id, 
                                                choice_index,
                                                stack_id,
                                                term_id: currentTerm?.id,
                                              } 
                                            })
                                          }
                                          catch (e) {
                                            track({ 
                                              supabase, 
                                              profile: currentProfile, 
                                              t: 'e', 
                                              p: { 
                                                event: "submit_answer", 
                                                paragraph_id: paragraph?.id, 
                                                choice_index,
                                                stack_id,
                                                term_id: currentTerm?.id,
                                                e: e?.message
                                              } 
                                            })
                                          }

                                          if (choices[selectedAnswers?.[paragraph.id]]?.correct) {
                                            setGainedDiamonds({
                                              ...gainedDiamonds,
                                              [paragraph.id]: data?.diamonds
                                            })
                                          }

                                        } }
                                      >
                                        SUBMIT
                                      </button>
                                    </div>
                                  }
                                  {
                                    correctAnswers?.[paragraph.id] && <div class="Congrats">
                                      <img src={goldStarIcon} alt="" />
                                      <p>
                                        <h3>Congratulations!</h3>
                                        <span>You got the correct answer 😁</span>
                                        <div className="Earnings">
                                          <div className="EarningsNotice">You just earned {gainedDiamonds[paragraph.id] == null ? <div className="EarningsNoticeImg"><img src={loadingBarsIcon} alt="" /></div> : <b>&nbsp;{gainedDiamonds[paragraph.id]}</b>}
                                          </div>
                                          <img src={diamond63Icon} alt=""/>
                                        </div>
                                      </p>
                                    </div>
                                  }
                                </p>
                              }
                            </div>
                          </div>
                        })
                      }
                    </div>
                  </div>
                }
                {
                  descriptionLoading && <div>&nbsp;<b>Loading ...</b></div>
                }
              </>
            }
            {
              hasChallenge &&
              isChallenge && <>
                <div className="TermDescription">
                  <Challenge
                    supabase={supabase}
                    currentProfile={currentProfile}
                    stack_id={stack_id}
                    term_id={currentTerm?.id}
                    termdata={challengeTermdata}
                    fetchAndPlayAudio={fetchAndPlayAudio} 
                    reading={reading} 
                    setReading={setReading} 
                    stopAudio={stopAudio}
                  />
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  
  </>);
}

export default Stack

/*
  const [ currentDescription, setCurrentDescription ] = useState(null);
  const [ currentLangDescription, setCurrentLangDescription ] = useState(null);

  setCurrentLangDescription(null);

  getTerm({ term: currentTerm });

  async function getTerm({ term }) {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/v1/terms/${term?.id}`;
    const r = await axios.get(url, { headers: { 'Authorization': `Bearer ${supabase.realtime.accessToken}` } });
    setCurrentDescription(r?.data?.data?.description);
    setDescriptionLoading(false);
  }

  // const splitDescription = (lang?.includes('cmn-') || lang?.includes('yue-')) && currentLangDescription ? [...currentLangDescription] : 
  //   currentLangDescription ? currentLangDescription.split(" ") :
  //   currentDescription ? currentDescription.split(" ") : []
  // const splitDescription = currentDescription?.includes('\n\n') ? currentDescription.split('\n\n') :
  //   currentDescription ?  [currentDescription] : []

  if (termList.clientHeight >= 738) {
    console.log("fetch more (zero)")
  }
  useEffect(() => {
    console.log("lang", lang)

    if (lang === 'en-US') {
      setCurrentLangTerm(null)
      setCurrentLangDescription(null)
    }
    else if (lang === 'cmn-TW') {
      getChineseterm({ term: currentTerm })
    }
    else if (lang === 'yue-HK') {
      getChineseterm({ term: currentTerm })
    }
    else if (lang === 'cmn-CN') {
      getChineseterm({ term: currentTerm })
    }
    else if (lang === 'es-ES') {
      getSpanishterm({ term: currentTerm })
    }

  }, [lang]);
  
  <div className="TermLang">
    <div className="LanguageSelect">
      <select value={lang} onChange={ e => { setLang(e.target.value) }} >
        <option value="en-US">English</option>
        <option value="es-ES">Espoñol</option>
        <option value="cmn-CN">简体中文</option>
        <option value="cmn-TW">繁體中文 (國語)</option>
        <option value="yue-HK">繁體中文 (粵語)</option>
      </select>
    </div>
  </div>

  async function getChineseterm({ term }) {
    setDescriptionLoading(true);
    const url = `${process.env.REACT_APP_API_ENDPOINT}/v1/chineseterms?term_id=${term?.id}`;
    const r = await axios.get(url);
    const chineseterm = r.data?.data

    const tradChinese = (lang === 'cmn-TW' || lang === 'yue-HK')
    const name = tradChinese ? chineseterm?.name_trad : chineseterm?.name
    const description = tradChinese ? chineseterm?.description_trad : chineseterm?.description

    setCurrentLangTerm(name);
    setCurrentLangDescription(description);
    setDescriptionLoading(false);
  }

  async function getSpanishterm({ term }) {
    setDescriptionLoading(true);
    const url = `${process.env.REACT_APP_API_ENDPOINT}/v1/spanishterms?term_id=${term?.id}`;
    const r = await axios.get(url);
    const spanishterm = r.data?.data

    setCurrentLangTerm(spanishterm.name);
    setCurrentLangDescription(spanishterm.description);
    setDescriptionLoading(false);
  }

*/